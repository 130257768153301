import React from 'react';
import '../../style/style-pages/homepage/Statistics.css';

const Statistics = () => {
  return (
    <div className='statistics__main container'>
        <div className="tablet:px-20">
            <h2>Impact Statistics</h2>
            <div className="statistics__content flex flex-col tablet:flex-row justify-between gap-6 pt-16">
                <div className="statistics__card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                        <path d="M62.3327 77V69.6667C62.3327 65.7768 60.7875 62.0463 58.0369 59.2958C55.2864 56.5452 51.5559 55 47.666 55H18.3327C14.4428 55 10.7123 56.5452 7.96178 59.2958C5.21125 62.0463 3.66602 65.7768 3.66602 69.6667V77" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M33.0007 40.3333C41.1008 40.3333 47.6673 33.7668 47.6673 25.6667C47.6673 17.5665 41.1008 11 33.0007 11C24.9005 11 18.334 17.5665 18.334 25.6667C18.334 33.7668 24.9005 40.3333 33.0007 40.3333Z" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M84.334 76.9999V69.6666C84.3316 66.4169 83.25 63.2601 81.259 60.6917C79.268 58.1234 76.4805 56.289 73.334 55.4766" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M58.666 11.4766C61.8209 12.2843 64.6172 14.1191 66.614 16.6917C68.6109 19.2643 69.6948 22.4283 69.6948 25.6849C69.6948 28.9415 68.6109 32.1055 66.614 34.6781C64.6172 37.2507 61.8209 39.0855 58.666 39.8932" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <h1>32+</h1>
                    <h3 className='text-[var(--clr-gray500)]'>Families Supported</h3>
                </div>
                <div className="statistics__card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                        <g clipPath="url(#clip0_451_3952)">
                            <path d="M67.8328 51.3333H20.1661C18.7074 51.3333 17.3085 50.7539 16.277 49.7224C15.2456 48.691 14.6661 47.292 14.6661 45.8333V18.3333C14.6661 16.3884 15.4387 14.5232 16.814 13.1479C18.1893 11.7726 20.0545 11 21.9995 11H29.3328M69.6627 77H18.3362C17.2014 77 16.082 76.7366 15.0662 76.2305C14.0504 75.7243 13.166 74.9893 12.4826 74.0833C11.7991 73.1773 11.3353 72.125 11.1277 71.0093C10.92 69.8936 10.9742 68.7449 11.2859 67.6537L15.1884 53.9928C15.4073 53.2266 15.8698 52.5526 16.506 52.0727C17.1421 51.5929 17.9173 51.3333 18.7141 51.3333H69.2848C70.0816 51.3333 70.8568 51.5929 71.4929 52.0728C72.129 52.5526 72.5915 53.2267 72.8104 53.9928L76.713 67.6537C77.0247 68.7449 77.0789 69.8936 76.8712 71.0093C76.6636 72.125 76.1998 73.1773 75.5163 74.0833C74.8329 74.9893 73.9485 75.7243 72.9327 76.2305C71.9169 76.7366 70.7976 77 69.6627 77Z" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M36.666 66H51.3327" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M80.6673 28.6C80.6673 29.378 80.3583 30.1241 79.8082 30.6742C79.2581 31.2243 78.512 31.5333 77.734 31.5333H71.8673V37.4C71.8673 38.178 71.5583 38.9241 71.0082 39.4742C70.4581 40.0243 69.712 40.3333 68.934 40.3333H63.0673C62.2894 40.3333 61.5432 40.0243 60.9931 39.4742C60.443 38.9241 60.134 38.178 60.134 37.4V31.5333H54.2673C53.4894 31.5333 52.7432 31.2243 52.1931 30.6742C51.643 30.1241 51.334 29.378 51.334 28.6V22.7333C51.334 21.9554 51.643 21.2093 52.1931 20.6592C52.7432 20.109 53.4894 19.8 54.2673 19.8H60.134V13.9333C60.134 13.1554 60.443 12.4093 60.9931 11.8592C61.5432 11.309 62.2894 11 63.0673 11H68.934C69.712 11 70.4581 11.309 71.0082 11.8592C71.5583 12.4093 71.8673 13.1554 71.8673 13.9333V19.8H77.734C78.512 19.8 79.2581 20.109 79.8082 20.6592C80.3583 21.2093 80.6673 21.9554 80.6673 22.7333V28.6Z" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M33 25.6667H36.6667" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M40.334 36.6667H44.0007" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M40.334 14.5457H44.0007" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_451_3952">
                            <rect width="88" height="88" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <h1>$15,025</h1>
                    <h3 className='text-[var(--clr-gray500)]'>Raised for Research and Support</h3>
                </div>
                <div className="statistics__card">
                    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                        <g clipPath="url(#clip0_589_4503)">
                            <path d="M58.2597 29.3333H29.7403C25.465 29.3333 22 25.8683 22 21.593V18.7403C22 14.465 25.465 11 29.7403 11H58.2597C62.535 11 66 14.465 66 18.7403V21.593C66 25.8683 62.535 29.3333 58.2597 29.3333Z" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M58.055 76.9999H29.9463C23.5333 76.9999 18.334 71.8006 18.334 65.3876V40.9419C18.334 34.5326 23.5333 29.3333 29.9463 29.3333H58.0586C64.468 29.3333 69.6673 34.5326 69.6673 40.9456V65.3913C69.6673 71.8006 64.468 76.9999 58.055 76.9999Z" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M44 46.0532V61.5266" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M51.737 53.79H36.2637" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M44 19.4333V21.3033" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M33.918 19.4333V21.3033" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M54.084 19.4333V21.3033" stroke="#808080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_589_4503">
                            <rect width="88" height="88" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <h1>700+</h1>
                    <h3 className='text-[var(--clr-gray500)]'>Hydroxyurea Pills Distributed</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Statistics;