import React, { useState } from 'react'
import NewsCard from './newsCard';
import images from '../../utils/imageLoader';
import { muiTheme } from '../..';
import { Pagination, PaginationItem, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NewsSection = () => {
    const [page, setPage] = useState(1);
    const cardsPerPage = 2;
    
    const newsCards = [
        { title: "2024 First Quater Event", image: images["NewsEvents__Card3.png"] },
        { title: "Sickle Cell Month Nationwide Information", image: images["NewsEvents__Card4.png"] },
        { title: "Sickle Cell Month Nationwide Information", image: images["NewsEvents__Card4.png"] }
    ];

    const startIndex = (page - 1) * cardsPerPage;
    const selectedCards = newsCards.slice(startIndex, startIndex + cardsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    return (
        <>
            <div className='news_events_resources__news_content grid grid-rows-1 grid-cols-1 laptop:grid-cols-2 gap-6 py-6'>
                {selectedCards.map((item, index) => (
                    <NewsCard key={index} title={item.title} image={item.image} />
                ))}
            </div>
            <ThemeProvider theme={muiTheme}>
                <Pagination 
                    count={Math.ceil(newsCards.length / cardsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color='darkBackground' 
                    shape='rounded' 
                    sx={{ marginTop: "32px", display: "flex", justifyContent: "center" }} 
                    renderItem={(item) => (
                        <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    )
}

export default NewsSection;