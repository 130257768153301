import React from 'react'

const ConstructionAlert = () => {
    return (
        <div className='bg-[var(--clr-blue-light)] text-[var(--clr-blue)] flex justify-center items-center gap-2 p-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.40741 8.33335H12.5926M5.78703 12.5H14.213M2.5 16.6667H17.5M4.16667 16.6667L8.58333 5.3095C9.02392 4.17664 9.24417 3.6102 9.56133 3.44511C9.83625 3.30199 10.1637 3.30199 10.4387 3.44511C10.7558 3.6102 10.9761 4.17664 11.4167 5.3095L15.8333 16.6667" stroke="#0055FF" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='text-center font-light text-[14px]'>Thank you for visiting! Our site is still under construction, but we’re working everyday to enhance your experience.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.40741 8.33335H12.5926M5.78703 12.5H14.213M2.5 16.6667H17.5M4.16667 16.6667L8.58333 5.3095C9.02392 4.17664 9.24417 3.6102 9.56133 3.44511C9.83625 3.30199 10.1637 3.30199 10.4387 3.44511C10.7558 3.6102 10.9761 4.17664 11.4167 5.3095L15.8333 16.6667" stroke="#0055FF" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

export default ConstructionAlert;