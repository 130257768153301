import React, { useState } from 'react'
import { muiTheme } from '../..';
import { Pagination, PaginationItem, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ResourcesCard from './resourcesCard';

const ResourcesVideosSection = () => {
    const [page, setPage] = useState(1);
    const cardsPerPage = 4;
    
    const resourcesCards = [
        {
            title: "A Dialogue On Sickle Cell",
            description: "In this open conversation with friends, Emmanuel dives into the topic of Sickle Cell Disease from a personal and relatable perspective.",
            link: "https://youtu.be/uOdoK2fVh4Q?si=9VlBYnH43_8lKEcX"
        },
        {
            title: "A Dialogue On Sickle Cell Trailer",
            description: "In this open conversation with friends, Emmanuel dives into the topic of Sickle Cell Disease from a personal and relatable perspective.",
            link: "https://youtu.be/OgZD3flNPgo?si=7DgRJQCgR6rfB2d9"
        },
        { 
            title: "Eben Family Sickle Cell Foundation 2023-2024",
            description: "This September, learn from experts, connect with fellow warriors, and advocate for better research funding, access to care, and policy advancements.",
            link: "https://youtu.be/XZHU0doAK1o?si=8nYUXJT93Clu0x9P"
        }
    ];

    const startIndex = (page - 1) * cardsPerPage;
    const selectedCards = resourcesCards.slice(startIndex, startIndex + cardsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    return (
        <>
            <div className='news_events_resources__videos_content grid grid-rows-1 grid-cols-1 laptop:grid-cols-3 gap-6 py-6'>
                {selectedCards.map((item, index) => (
                    <ResourcesCard 
                        title={item.title}
                        description={item.description}
                        link={item.link}
                    />
                ))}
            </div>
            <ThemeProvider theme={muiTheme}>
                <Pagination 
                    count={Math.ceil(resourcesCards.length / cardsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color='darkBackground' 
                    shape='rounded' 
                    sx={{ marginTop: "32px", display: "flex", justifyContent: "center" }} 
                    renderItem={(item) => (
                        <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    )
}

export default ResourcesVideosSection;