import React from 'react'
import ButtonSecondary from '../buttons/buttonSecondary';
import '../../style/style-pages/news-resources/NewsResources.css';

const ResourcesCard = ({ title = "Title", description = "Description: ", link = "/", setDisabled = false }) => {
    return (
        <div className="news_events_resources__card news_events_resources__card_sm">
            <div className="news_events_resources__card_content">
                <div className='flex flex-col gap-2'>
                    <h4>{title}</h4>
                </div>
                <p className='text-[16px]'>{description}</p>
                <div className="news_events_resources__card_btn ml-auto">
                    <ButtonSecondary 
                        link={link} 
                        linkNewTab={true} 
                        setDisabled={ link === "/" ? true : setDisabled }
                        btnText="LEARN MORE" 
                        border={ link === "/" ? false : !setDisabled } 
                    />
                </div>
            </div>
        </div>
    )
}

export default ResourcesCard;