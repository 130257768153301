import React from 'react'
import IntroModal from '../../components/introModal';
import Hero from './Hero';
import IntroCards from './IntroCards';
import NewsEvents from './NewsEvents';
// import HpResources from './Hp_Resources';
import HpContact from './Hp_Contact';
import HpGallery from './Hp_Gallery';

const Homepage = () => {
    return (
        <>
            <IntroModal />
            <div className='Hero bg-slate-300'><Hero /></div>
            <section className='Intro bg-[var(--clr-white)]'><IntroCards /></section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,30 C150,70 285,0 432,0 C588,0 726,90 888,90 C1012,90 1188,60 1440,100 L1440,120 L0,120 Z"></path></svg>
            <section className='Intro bg-[var(--clr-dark-background)] text-[var(--clr-white)]'><HpGallery /></section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,20 C150,50 285,100 432,100 C588,100 726,10 888,10 C1012,10 1188,40 1440,100 L1440,0 L0,0 Z"></path></svg>
            <section className='News_Events bg-[var(--clr-white)]'><NewsEvents /></section>
            {/* <section className='HpResources bg-[var(--clr-ruddy)]'><HpResources /></section> */}
            <section className='HpContact bg-[var(--clr-white)]'><HpContact /></section>
        </>
    )
}

export default Homepage;