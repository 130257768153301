import React from 'react'
import images from '../utils/imageLoader';

const Newsletter = () => {
    return (
        <section className='pt-32 pb-[64px] desktop:pb-[192px]'>
                {/* <div className='absolute w-full h-full -z-10'>
                </div> */}
                <div className='absolute w-full h-full -z-10'>
                    <img className='relative top-[-125px] left-0 w-full h-[24.75%] object-cover' src={images['Newsletter__Divider.png']} alt="newsletter_divider" />
                    <img className='relative top-[-128px] left-0 w-full h-full object-cover' src={images['Newsletter__Background.png']} alt="newsletter_background" />
                </div>
                <div className=" text-white flex flex-col gap-10 max-w-[696px] mx-auto px-4 tablet:p-0">
                    <div>
                        <h2>Newsletter</h2>
                        <p className="t4">Stay Informed with Our Newsletter</p>
                    </div>
                    
                    <div className="deftform" 
                        data-form-id="91463854-49e3-4e73-894e-f6e082b4922e" 
                        data-form-width="100%" 
                        data-form-height="800px" 
                        data-form-auto-height="1">
                    </div>
                </div>
        </section>
    )
}

export default Newsletter;