import React from 'react'
import images from '../../utils/imageLoader';

const ImpactAchievements = () => {
    return (
        <>
            <section className="new_page">
                <div className="impact_achievements__main flex flex-col gap-16 container">
                    <div className='flex flex-col gap-2'>
                        <h2>Impact and Achievements</h2>
                        <p className="t4">Making a Difference in the Sickle Cell Community</p>
                        <p className="t5">At Eben Family Sickle Cell Foundation, our mission is to transform the lives of individuals and families affected by Sickle Cell Disease. We achieve this by providing essential support services, delivering education to both patients and healthcare providers, advocating for policy changes that improve care, and advancing groundbreaking research efforts. Through our holistic approach, we strive to ensure that every person impacted by Sickle Cell Disease has access to the resources and care they need, while also working to raise awareness and drive progress towards a cure.</p>
                    </div>

                    <div className="grid grid-rows-auto grid-cols-1 tablet:grid-rows-auto tablet:grid-cols-2 laptop:grid-rows-auto laptop:grid-cols-3 gap-12">
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement1.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Impact and Achievement</h3>
                                <p className="t4">Transforming Lives Through Our Work</p>
                                <p>
                                    Eben Family Sickle Cell Foundation has made a significant impact on the community through its dedicated programs and initiatives. By expanding healthcare access, educating individuals and families, advocating for better healthcare policies, and supporting research efforts, we’ve empowered those affected by Sickle Cell Disease. Our programs have provided critical resources, enabling early detection, better disease management, and fostering a supportive community. With each initiative, we work to improve the quality of life for patients and advance the pursuit of a cure for Sickle Cell Disease.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement2.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Key Achievements</h3>
                                <p className="t4">Our Milestones and Progress</p>
                                <ul className="list-disc pl-6">
                                    <li>
                                        <p>
                                            Patients Supported: Over 1,200 patients have received crucial support, including medical services, counseling, and access to medication through our programs, improving their quality of life and disease management.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Funds Raised: To date, the foundation has raised over $750,000, channeling these funds toward Sickle Cell Disease research, patient support, and advocacy initiatives that have brought us closer to finding better treatments.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Educational Programs Conducted: The foundation has conducted 300+ workshops, seminars, and public awareness campaigns focused on early detection, Sickle Cell management, and educating both healthcare providers and the public.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Volunteer Engagement: Our dedicated team of 500 volunteers has collectively contributed over 10,000 hours, assisting in everything from patient care and outreach efforts to organizing community events.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Policy Changes Influenced: Through persistent advocacy work, the foundation has successfully influenced 5 key policy changes, improving healthcare access and support systems for individuals affected by Sickle Cell Disease across various regions.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement3.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Stories of Hope and Change</h3>
                                <p className="t4">Real Stories from Those We’ve Helped</p>
                                <div>
                                    <p>
                                        Featured Story: A Life Transformed by Hope <br />
                                        Meet [Patient Name], whose journey with sickle cell disease has been one of resilience and determination. Thanks to the support of the Eben Family Sickle Cell Foundation, they received not only life-saving medical care but also emotional and educational support for their family. [Patient Name] shares their story of triumph and how the foundation’s programs changed their life for the better. <br />
                                        Additional Stories: Real Lives, Real Impact <br />
                                        Explore more inspiring stories from patients, families, and volunteers who have been positively impacted by our work. Whether through access to healthcare, educational programs, or advocacy efforts, their stories reflect the heart and mission of the foundation.
                                    </p>
                                    <strong>Discover More:</strong>
                                    <ul className="list-disc pl-6">
                                        <li>
                                            <p>
                                                [Patient Story 1]
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                [Volunteer Experience]
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                [Family's Journey]
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Call to Action <br />
                                        Want to read more powerful stories of hope and transformation? [Read More Impact Stories] – Explore more patient testimonials, videos, and personal accounts on our dedicated Impact Stories page.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement4.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Visualizing Our Impact</h3>
                                <p className="t4">Understanding Our Reach and Effectiveness</p>
                                <div>
                                    <p>
                                        Visualizing Our Impact <br />
                                        Infographics: Achievements in Numbers <br />
                                        To provide a clearer picture of our foundation's successes, we use detailed infographics to represent our achievements visually. <br />
                                        These include:
                                    </p>
                                    <ul className="list-disc pl-6">
                                        <li>
                                            <p>
                                                Patients Supported: A bar chart or graph showing the increasing number of patients who have received support over the years.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Funds Raised: A pie chart breaking down the sources of the $750,000 raised for research, advocacy, and patient support.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Volunteer Engagement: A visual timeline depicting the growth in volunteer hours, currently totaling over 10,000 hours.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Educational Programs Conducted: A gauge or bar chart reflecting the 300+ workshops and seminars conducted, raising awareness about sickle cell disease.
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Maps: Expanding Our Reach <br />
                                        We are committed to spreading our support far and wide. The map below highlights our program locations, showcasing where our services have been implemented:
                                    </p>
                                    <ul className="list-disc pl-6">
                                        <li>
                                            <p>
                                                National Reach: Pins on key cities where we've provided patient care, education, and advocacy efforts.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Global Outreach: A global map displaying our partnerships and collaborations with healthcare organizations, research institutions, and advocacy groups in countries impacted by sickle cell disease.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement5.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Working Together for a Greater Impact</h3>
                                <p className="t4">Our Partners and Supporters</p>
                                <div>
                                    <strong>
                                        Our Partners
                                    </strong>
                                    <p>
                                        Key Contributors to Our Success
                                    </p>
                                    <p>
                                        The Eben Family Sickle Cell Foundation has been fortunate to collaborate with a number of dedicated partners and sponsors who have significantly contributed to our mission. These include:
                                    </p>
                                    <ul className="list-disc pl-6">
                                        <li>
                                            <p>
                                                Healthcare Institutions: Hospitals and medical centers providing patient care and conducting research.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Pharmaceutical Companies: Sponsors contributing to research and development of treatments for sickle cell disease.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nonprofits and Advocacy Groups: Collaborating on outreach, education, and advocacy efforts.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Corporate Sponsors: Offering financial support and resources for our events, programs, and campaigns.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Community Organizations: Working with us to raise awareness and provide localized support to families affected by sickle cell.
                                            </p>
                                        </li>
                                    </ul>
                                    <strong>
                                        Collaborative Projects
                                    </strong>
                                    <p>
                                        We have undertaken several impactful projects thanks to our partnerships:
                                    </p>
                                    <ul className="list-disc pl-6">
                                        <li>
                                            <p>
                                                Healthcare Access Initiative: In collaboration with several hospitals, we’ve increased patient access to quality healthcare services in underserved areas.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Educational Workshops: Partnering with schools and local community groups, we’ve hosted over 300+ educational workshops to raise awareness about sickle cell disease.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Policy Advocacy Campaign: Through joint efforts with advocacy groups, we’ve influenced legislative changes to improve healthcare policies for individuals with sickle cell disease.
                                            </p>
                                        </li>
                                    </ul>
                                    <strong>
                                        Call to Action
                                    </strong>
                                    <p>
                                        Learn About Our Partners 
                                    </p>
                                    <p>
                                        Explore the companies, organizations, and individuals who have made our work possible. [Leads to the Partners & Sponsors page]
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <img src={images['ImpactAchievements__Achievement6.png']} alt="achievement-card" />
                            <div className='flex flex-col gap-2'>
                                <h3>Get Involved and Make an Impact</h3>
                                <p className="t4">Join Us in Making a Difference</p>
                                <p>
                                    <strong>Donate</strong> <br />
                                    Make a Difference Today <br />
                                    Your contributions help us continue providing vital support, education, and advocacy for individuals and families affected by sickle cell disease. Every donation goes directly to expanding our healthcare access programs, conducting research, and advocating for policy changes. Your generosity makes a lasting impact on the lives of those in need. <br />
                                    [Donate Now] <br /><br />

                                    <strong>Volunteer</strong> <br />
                                    Join Our Mission <br />
                                    Are you passionate about making a difference in the community? By volunteering with the Eben Family Sickle Cell Foundation, you can help support our programs, raise awareness, and provide much-needed services to patients and families. Whether you’re a healthcare professional, educator, or simply someone who wants to give back, we welcome volunteers from all walks of life. <br />
                                    [Get Involved] <br /><br />

                                    <strong>Advocate </strong><br />
                                    Be a Voice for Change <br />
                                    Advocacy is crucial to our mission. Join us in supporting policy changes that improve healthcare access and quality of life for individuals with sickle cell disease. You can help by contacting lawmakers, participating in awareness campaigns, and sharing educational resources within your community. <br />
                                    [Learn How to Advocate]
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ImpactAchievements;