import React from 'react'
import EventDeftform from '../../components/eventDeftform';
import NewsSection from '../../components/newsEventsResourcesCards/newsSection';
import EventSection from '../../components/newsEventsResourcesCards/eventSection';
import ResourcesGuideSection from '../../components/newsEventsResourcesCards/resourcesGuideSection';
import ResourcesVideosSection from '../../components/newsEventsResourcesCards/resourcesVideosSection';
import ResourcesSupportSection from '../../components/newsEventsResourcesCards/resourcesSupportSection';
import '../../style/style-pages/news-resources/NewsResources.css';

const NewsResources = () => {
    return (
        <>
            <section className='new_page'>
                <div className='news_events_resources__main flex flex-col justify-between gap-16 container'>
                    <div>
                        <h2 className="pb-2">News, Events and Resources</h2>
                        <p className="t4">Stay informed with the latest updates and stories from the Eben Family Sickle Cell Foundation.</p>
                    </div>

                    <div className='news_events_resources__content flex flex-col justify-between gap-16'>
                        <div className="news_events_resources__news">
                            <div>
                                <h3>News</h3>
                                <div className="global__divider"></div>
                            </div>
                            <NewsSection />
                        </div>

                        <div className="news_events_resources__events">
                            <div>
                                <h3>Events</h3>
                                <div className="global__divider"></div>
                            </div>
                            <EventSection />
                        </div>

                        <div className="news_events_resources__resources flex flex-col justify-between gap-8">
                            <div>
                                <div>
                                    <h3>Brochures and Guides</h3>
                                    <div className="global__divider"></div>
                                </div>
                                <ResourcesGuideSection />
                            </div>

                            <div>
                                <div>
                                    <h3>Videos and Webinars</h3>
                                    <div className="global__divider"></div>
                                </div>
                                <ResourcesVideosSection />
                            </div>

                            <div>
                                <div>
                                    <h3>Patient Support</h3>
                                    <div className="global__divider"></div>
                                </div>
                                <ResourcesSupportSection />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default NewsResources;