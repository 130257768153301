import React from 'react'
import EventDeftform from '../../components/eventDeftform';
import images from '../../utils/imageLoader';
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <>
            <section className='new_page'>
                <div className="flex flex-col gap-32">
                    <div className="contact__main flex flex-col laptop:flex-row gap-16 container">
                        <div className='flex flex-col justify-start gap-6 laptop:w-1/2'>
                            <h2>Contact Us</h2>
                            <p className='t4'>We're here to help</p>
                            <p className="t5">
                                Whether you have a question, need support, or want to provide feedback, we encourage you to reach out to us. Your inquiries and contributions are invaluable to us, and we’re eager to assist in any way we can.
                            </p>
                            <p className="t5">
                                You can contact us through various channels—give us a call, send us an email, or connect with us on social media. We’re just a message away!
                                Your questions, concerns, and support are important to us, and we look forward to hearing from you.
                            </p>
                            <p className="t5">
                                Please feel free to contact us using the information below or by filling out the contact form.
                            </p>
                        </div>
                        <div className='flex flex-col gap-10 max-w-[696px] w-full laptop:w-1/2 mx-auto p-4 tablet:p-0'>
                            <div className="hp_contact__upper">
                                <h2 className='pb-4'>Get in Touch</h2>
                                <p className='t4'>Let us know how we can help</p>
                            </div>

                            <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                        </div>
                    </div>


                    <div className="get_in_touch__main flex flex-col laptop:flex-row gap-16 container">
                        <div className='flex flex-col justify-start laptop:w-1/2 gap-6'>
                            <div className="flex flex-col gap-2">
                                <h2>Contact Information</h2>
                                <p className="t4">Contact us Directly</p>
                            </div>
                            <div>
                                <strong className="text-[16px] laptop:text-[20px]">Phone Numbers:</strong>
                                <ul className="list-disc pl-6">
                                    <li><p>Main Office: +1 (555) 123-4567</p></li>
                                </ul>
                            </div>
                            <div>
                                <strong className="text-[16px] laptop:text-[20px]">Email Addresses:</strong>
                                <ul className="list-disc pl-6">
                                    <li><p>General Inquiries: info@ebenfamilyscf.org</p></li>
                                    <li><p>Support: support@ebenfamilyscf.org</p></li>
                                    <li><p>Volunteer Opportunities: volunteer@ebenfamilyscf.org</p></li>
                                </ul>
                            </div>
                            <div>
                                <strong className="text-[16px] laptop:text-[20px]">Physical Address:</strong>
                                <ul className="list-disc pl-6">
                                    <li><p>Eben Family Sickle Cell Foundation</p></li>
                                    <li><p>123 Main Street, Suite 456</p></li>
                                    <li><p>City, State, ZIP Code</p></li>
                                </ul>
                            </div>
                            <div>
                                <strong className="text-[16px] laptop:text-[20px]">Operating Hours:</strong>
                                <ul className="list-disc pl-6">
                                    <li><p>Monday to Friday: 9:00 AM - 5:00 PM</p></li>
                                    <li><p>Saturday: Closed</p></li>
                                    <li><p>Sunday: Closed</p></li>
                                </ul>
                            </div>
                            

                            <h2>Social Links</h2>
                            <ul className="grid grid-rows-3 grid-cols-2 laptop:grid-rows-2 laptop:grid-cols-3 desktop:grid-rows-1 desktop:grid-cols-6 gap-6">
                                <img src={images['Footer__Icon1_Black.svg']} alt="social-icon" />
                                <Link to="https://www.instagram.com/ebenfamilyscf/" target="_blank">
                                    <img src={images['Footer__Icon2_Black.svg']} alt="social-icon" />
                                </Link>
                                <img src={images['Footer__Icon3_Black.svg']} alt="social-icon" />
                                <Link to="https://www.youtube.com/@EbenFamilySickleCellFoundation" target="_blank">
                                    <img src={images['Footer__Icon4_Black.svg']} alt="social-icon" />
                                </Link>
                                <img src={images['Footer__Icon5_Black.svg']} alt="social-icon" />
                                <img src={images['Footer__Icon6_Black.svg']} alt="social-icon" />
                            </ul>
                        </div>
                        <div className='laptop:w-1/2'>
                            <img src={images['Contact__Map.png']} alt="foundation location" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;