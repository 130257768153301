import React from 'react'

const PrivacyPolicy = () => {
    return (
        <section className="new_page">
            <div className="privacy_policy__main flex flex-col gap-8 container">
                <div className='flex flex-col gap-2'>
                    <h2>Privacy Policy</h2>
                    <p className='t4'>Introduction</p>
                    <p className="t5">At the Eben Family Sickle Cell Foundation, we are dedicated to safeguarding the privacy and security of our website visitors and community members. We understand the importance of trust, especially when it comes to handling personal information. This Privacy Policy outlines how we collect, use, and protect your data when you interact with our site and services.
                    The purpose of this policy is to ensure transparency in how we manage personal information, explain the types of data we collect, and outline the ways we use and protect that information. Whether you're visiting our website, making a donation, or accessing our services, we are committed to upholding the highest standards of data protection and ensuring your information is treated with care and respect.</p>
                </div>


                <div className="flex flex-col gap-4">
                    <h2>Information We Collect</h2>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Personal Information</strong>
                        <p>We collect personal information that you voluntarily provide when you engage with the Eben Family Sickle Cell Foundation. This may include details such as your name, email address, mailing address, phone number, and any other contact information you choose to share, especially when making a donation, signing up for newsletters, or contacting us for support.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Non-Personal Information</strong>
                        <p>In addition to personal details, we also collect non-personal information automatically through the use of cookies, IP addresses, browser types, and other data analytics tools. This information helps us improve our website's functionality, user experience, and overall performance by understanding how visitors interact with our site.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Sensitive Health Information (if applicable)</strong>
                        <p>If the foundation collects health-related information, such as data about your medical history or participation in health programs, this information is treated with the highest level of confidentiality. We strictly adhere to legal guidelines to protect sensitive health data and ensure it is only used for the specific purposes of providing services or conducting research, with appropriate consent from individuals.</p>
                    </div>
                </div>


                <div className="flex flex-col gap-4">
                    <h2>How We Use the Information</h2>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Service Delivery</strong>
                        <p>We use the personal information you provide to deliver essential services offered by the Eben Family Sickle Cell Foundation. This includes sending you newsletters, providing access to educational resources, responding to your inquiries, and offering support services related to sickle cell disease. Your information helps us tailor our services to meet your needs and expectations.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Improving the Website</strong>
                        <p>Collected data, including non-personal information such as browser details and usage patterns, helps us continually enhance our website's performance. This information is critical for optimizing the user experience, improving navigation, and personalizing the content you see based on your interactions with the site.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Communication</strong>
                        <p>Your personal information may also be used to communicate with you about important updates, events, or promotions related to our foundation's work. We may send newsletters, event invitations, or notifications about new research and resources that may be of interest to you. You can choose to opt out of these communications at any time.</p>
                    </div>
                </div>


                <div className="flex flex-col gap-4">
                    <h2>Information Sharing and Disclosure</h2>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Third-Party Service Providers</strong>
                        <p>We may share your personal information with trusted third-party service providers who assist us in delivering services. These include providers that handle email marketing, manage donation platforms, or process payment transactions. Each of these providers is vetted for their privacy practices, and your data is shared only to the extent necessary to fulfill these services. We ensure that all third parties adhere to strict privacy and security standards.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Legal Compliance</strong>
                        <p>In certain situations, we may be required to disclose your information to comply with legal obligations. This includes instances where we are required by law, court orders, or other government authorities to provide information. We will only share the necessary details and will ensure that your data is handled securely during this process.</p>
                    </div>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">Data Sharing with Partners</strong>
                        <p>If applicable, we may share personal data with research or advocacy partners who align with our mission of improving healthcare and support for individuals affected by sickle cell disease. When such data is shared, we ensure that all information is anonymized to protect your identity, unless you have given explicit consent for otherwise. Any collaboration is done with partners who adhere to strict data protection guidelines. </p>
                    </div>
                </div>


                <div className="flex flex-col gap-4">
                    <h2>Security Measures</h2>
                    <div>
                        <strong className="text-[16px] laptop:text-[20px] pb-2">We are committed to ensuring the safety and security of your personal information. To protect your data, we have implemented a range of security measures, including:</strong>
                        <ul className="list-disc pl-6">
                            <li><p>Encryption: We use industry-standard encryption protocols to protect sensitive data during transmission, ensuring that your personal information remains confidential and secure.</p></li>
                            <li><p>Secure Servers: All personal information is stored on secure servers that are protected by firewalls, and access is restricted to authorized personnel only. These servers adhere to strict security practices to prevent unauthorized access.</p></li>
                            <li><p>Industry Standards: Our data protection practices follow industry standards such as the General Data Protection Regulation (GDPR) and other relevant privacy laws, ensuring your information is safeguarded in line with global best practices.</p></li>
                        </ul>
                    </div>
                    <strong className="text-[16px] laptop:text-[20px]">We regularly review and update our security measures to protect your personal data from unauthorized access, alteration, or disclosure.  </strong>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy;