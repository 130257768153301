import React from 'react'

const TermsConditions = () => {
    return (
        <section className="new_page">
            <div className="terms_conditions__main flex flex-col gap-8 container">
                <div className="flex flex-col gap-4">
                    <h2>Terms & Conditions</h2>
                    <p className="t4">Welcome to the Eben Family Sickle Cell Foundation website. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully. If you do not agree with any part of these terms, you must not use our website.</p>
                </div>
                <ul className="list-decimal pl-6 flex flex-col gap-4">
                    <div>
                        <li><strong>Acceptance of Terms</strong></li>
                        <p>By using the Eben Family Sickle Cell Foundation website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. These terms apply to all visitors, users, and others who access or use the website.</p>
                    </div>
                    <div>
                        <li><strong>Use of the Website</strong></li>
                        <p>You agree to use our website only for lawful purposes and in a way that does not infringe upon the rights of others or restrict or inhibit their use and enjoyment of the website. Prohibited activities include, but are not limited to:
                        Engaging in any conduct that is unlawful, harmful, or fraudulent.
                        Posting or transmitting any material that is abusive, defamatory, obscene, or otherwise objectionable.
                        Attempting to gain unauthorized access to our website or servers.</p>
                    </div>
                    <div>
                        <li><strong>Intellectual Property</strong></li>
                        <p>All content, including but not limited to text, graphics, logos, images, videos, and software, on the Eben Family Sickle Cell Foundation website is the property of the foundation or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or use any content from this website without prior written permission from the foundation.</p>
                    </div>
                    <div>
                        <li><strong>Donations</strong></li>
                        <p>All donations made through our website are voluntary and non-refundable. The Eben Family Sickle Cell Foundation reserves the right to use donations at its discretion to further its mission and objectives. We make every effort to ensure that your donation is used effectively and efficiently, but we do not guarantee that donations will be used for any specific purpose.</p>
                    </div>
                    <div>
                        <li><strong>Privacy Policy</strong></li>
                        <p>Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you consent to the collection and use of your information as outlined in our Privacy Policy.</p>
                    </div>
                    <div>
                        <li><strong>Links to Third-Party Websites</strong></li>
                        <p>Our website may contain links to third-party websites that are not owned or controlled by the Eben Family Sickle Cell Foundation. We are not responsible for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that we are not liable for any damages or losses caused by your use of or reliance on any third-party websites.</p>
                    </div>
                    <div>
                        <li><strong>Disclaimer of Warranties</strong></li>
                        <p>The Eben Family Sickle Cell Foundation website is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the operation of the website, its content, or any information provided. We do not warrant that the website will be error-free, secure, or uninterrupted, or that any defects will be corrected.</p>
                    </div>
                    <div>
                        <li><strong>Limitation of Liability</strong></li>
                        <p>In no event shall the Eben Family Sickle Cell Foundation or its directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the website, whether based on contract, tort, strict liability, or otherwise, even if advised of the possibility of such damages.</p>
                    </div>
                    <div>
                        <li><strong>Changes to the Terms</strong></li>
                        <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of the revised terms. We encourage you to review these terms periodically to stay informed of any updates.</p>
                    </div>
                    <div>
                        <li><strong>Governing Law</strong></li>
                        <p>These terms and conditions are governed by and construed in accordance with the laws of the state of [Your State], without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in [Your State] for the resolution of any disputes arising out of or relating to these terms or your use of the website.</p>
                    </div>
                    <div>
                        <li><strong>Contact Information</strong></li>
                        <p>If you have any questions or concerns about these terms and conditions, please contact us at: info@ebenfamilyscf.org</p>
                    </div>
                    <div>
                        <p>This document outlines the essential terms and conditions for users of the Eben Family Sickle Cell Foundation website. Adjustments can be made to fit the specific legal requirements of your location or organization.</p>
                    </div>
                </ul>
            </div>
        </section>
    )
}

export default TermsConditions;