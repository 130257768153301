import React from 'react'
import images from '../../utils/imageLoader';
import ButtonSecondary from '../../components/buttons/buttonSecondary';
import EventDeftform from '../../components/eventDeftform';

const ProgramOverview = () => {
    return (
        <>
            <section className="new_page">
                <div className="programs_overview__main flex flex-col gap-16 container">
                    <div className="flex flex-col gap-4">
                        <h2>Program Overview</h2>
                        <p className="t4">Empowering Lives through Comprehensive Support</p>
                        <p className="t5">A summary of the foundation’s programs, emphasizing their impact on individuals and communities affected by sickle cell disease.</p>
                    </div>

                    <div className="grid grid-rows-4 grid-cols-1 laptop:grid-rows-2 laptop:grid-cols-2 gap-10">
                        <div className='flex flex-col max-w-[700px]'>
                            <div className='relative pb-[56.25%] h-0'>
                                <img className='absolute left-0 top-0 w-full h-full object-cover grayscale' src={images['ProgramOverview__Program1.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-col gap-2">
                                    <h5>Support Services</h5>
                                    <p className="t5">Providing essential resources, counseling, and emotional support to sickle cell patients and their families.</p>
                                    <p>
                                        Key Programs:
                                        Patient Support Groups: Regular meetings and online communities for patients and families.
                                        Emergency Assistance: Financial support and resources for those in immediate need.
                                        Family Counseling: Therapy sessions and counseling for families affected by sickle cell disease.
                                    </p>
                                </div>
                                <div className="ml-auto">
                                    <ButtonSecondary btnText="Learn More" border={true} link="/our-work/program-overview/programs" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[700px]'>
                            <div className='relative pb-[56.25%] h-0'>
                                <img className='absolute left-0 top-0 w-full h-full object-cover grayscale' src={images['ProgramOverview__Program2.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-col gap-2">
                                    <h5>Education & Awareness</h5>
                                    <p className="t5">Educating the public and healthcare professionals about sickle cell disease.</p>
                                    <p>
                                        Key Programs:
                                        Community Workshops: Educational workshops in local communities.
                                        Healthcare Professional Training: Seminars and resources for medical staff.
                                        Public Awareness Campaigns: Nationwide campaigns to raise awareness about sickle cell disease.
                                    </p>
                                </div>
                                <div className="ml-auto">
                                    <ButtonSecondary btnText="Learn More" border={true} link="/our-work/program-overview/programs" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[700px]'>
                            <div className='relative pb-[56.25%] h-0'>
                                <img className='absolute left-0 top-0 w-full h-full object-cover grayscale' src={images['ProgramOverview__Program3.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-col gap-2">
                                    <h5>Advocacy</h5>
                                    <p className="t5">Advocating for better healthcare policies and increased funding for sickle cell research.</p>
                                    <p>
                                        Key Programs:
                                        Policy Advocacy: Working with lawmakers to improve healthcare policies.
                                        Patient Rights: Ensuring that patients receive the care and respect they deserve.
                                        Public Policy Events: Organizing events to raise awareness among policymakers.
                                    </p>
                                </div>
                                <div className="ml-auto">
                                    <ButtonSecondary btnText="Learn More" border={true} link="/our-work/program-overview/programs" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[700px]'>
                            <div className='relative pb-[56.25%] h-0'>
                                <img className='absolute left-0 top-0 w-full h-full object-cover grayscale' src={images['ProgramOverview__Program4.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-col gap-2">
                                    <h5>Research & Innovation</h5>
                                    <p className="t5">Supporting cutting-edge research and innovations in the treatment of sickle cell disease.</p>
                                    <p>
                                        Key Programs:
                                        Research Grants: Funding innovative research projects.
                                        Clinical Trials: Providing access to new treatments and therapies through clinical trials.
                                        Collaborative Research: Partnering with universities and research institutions.
                                    </p>
                                </div>
                                <div className="ml-auto">
                                    <ButtonSecondary btnText="Learn More" border={true} link="/our-work/program-overview/programs" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default ProgramOverview;