import React from 'react'
import AboutUsValues from './AboutUsValues';
import Statistics from '../homepage/Statistics';
// import Team from './Team';
import EventDeftform from '../../components/eventDeftform';

const AboutUs = () => {
    return (
        <>
            <section className='new_page'><AboutUsValues /></section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,30 C150,70 285,0 432,0 C588,0 726,90 888,90 C1012,90 1188,60 1440,100 L1440,120 L0,120 Z"></path></svg>
            <section className='Intro bg-[var(--clr-dark-background)] text-[var(--clr-white)]'><Statistics /></section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,20 C150,50 285,100 432,100 C588,100 726,10 888,10 C1012,10 1188,40 1440,100 L1440,0 L0,0 Z"></path></svg>
            {/* <section><Team /></section> */}


            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default AboutUs;