import React, { useEffect } from 'react';

const EventDeftform = ({ formId }) => {
    useEffect(() => {
        const existingScript = document.getElementById('deftform-script');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = "https://cdn.deftform.com/embed.js";
            script.async = true;
            script.id = 'deftform-script';
            document.body.appendChild(script);
        } else {
            window.location.reload();
        }
    }, []);

    return (
        <div>
            <div className="deftform" 
                data-form-id={formId} 
                data-form-width="100%" 
                data-form-height="800px" 
                data-form-auto-height="1">
            </div>
        </div>
    );
};

export default EventDeftform;
