import React, { useState } from 'react'
import { muiTheme } from '../..';
import { Pagination, PaginationItem, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ResourcesCard from './resourcesCard';

const ResourcesSupportSection = () => {
    const [page, setPage] = useState(1);
    const cardsPerPage = 4;
    
    const resourcesCards = [
        { 
            title: "Texas Health and Human Services",
            description: "Information on local and online support groups for patients and families.",
            link: "https://www.dshs.texas.gov/newborn-screening-program/sickle-cell-disease/sickle-cell-resources"
        },
        { 
            title: "Sickle Cell Disease",
            description: "Official webpage from the Center for Disease Control and Prevention",
            link: "https://www.cdc.gov/sickle-cell"
        }
    ];

    const startIndex = (page - 1) * cardsPerPage;
    const selectedCards = resourcesCards.slice(startIndex, startIndex + cardsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    return (
        <>
            <div className='news_events_resources__support_content grid grid-rows-1 grid-cols-1 laptop:grid-cols-4 gap-6 py-6'>
                {selectedCards.map((item, index) => (
                    <ResourcesCard 
                        title={item.title}
                        description={item.description}
                        link={item.link}
                    />
                ))}
            </div>
            <ThemeProvider theme={muiTheme}>
                <Pagination 
                    count={Math.ceil(resourcesCards.length / cardsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color='darkBackground' 
                    shape='rounded' 
                    sx={{ marginTop: "32px", display: "flex", justifyContent: "center" }} 
                    renderItem={(item) => (
                        <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    )
}

export default ResourcesSupportSection;