import React from 'react'
import ResourcesVideosSection from '../../components/newsEventsResourcesCards/resourcesVideosSection';

const ResourcesVideosPage = () => {
    return (
        <>
            <section className="new_page">
                <div className="videos__main flex flex-col gap-16 container">
                    <div className="videos__upper">
                        <h2>Educational Videos</h2>
                        {/* <div className="global__divider" /> */}
                        <p className="t4 pt-2">Join Us in Our Mission to Support the Sickle Cell Community</p>
                        <p className='pt-2'>A list of all the videos created by Eben Family Sickle Cell Foundation</p>
                    </div>

                    <div className='news_events_resources__resources'>
                        <div>
                            <h3>Videos and Webinars</h3>
                            <div className="global__divider"></div>
                        </div>
                        <ResourcesVideosSection />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResourcesVideosPage;