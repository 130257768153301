import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';

// function handleClick( event ) {
//     event.preventDefault();
//     console.log('You clicked a breadcrumb.');
// }

const Breadcrumbs = () => {
    const location = useLocation();

    if (location.pathname === '/') {
        return null;
    }

    let currentLink = '';
    const crumbs = location.pathname.split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`
            const crumbText = crumb.replace(/-/g, ' ');

            return (
                <div className='crumb hover:underline' key={crumb}>
                    <Link to={currentLink}>
                        {crumbText}
                    </Link>
                </div>
            )
        });

    // Prepend the Home crumb to the beginning of the crumbs array
    const homeCrumb = (
        <div className='crumb hover:underline' key="home">
            <Link to="/">
                home
            </Link>
        </div>
    );

    // Add "Home" to the beginning of the crumbs array
    const fullCrumbs = [homeCrumb, ...crumbs];

    return (
        <div className="container">
            <MuiBreadcrumbs role='navigation' separator='>' aria-label='breadcrumb'>
                {fullCrumbs}
            </MuiBreadcrumbs>
        </div>
    )
}

export default Breadcrumbs;