import React from 'react'
import { useMobileNav } from '../utils/MobileNavContext';
import { handleNavClick } from '../utils/handleNavClick';
import '../style/style-components/mobile-nav.css';
import { Link } from 'react-router-dom';

const MobileNavigation = () => {
    const { handleMenuBurger } = useMobileNav();

    return (
        <div className='mobile-nav__screen'>
            <menu className='mobile-nav__menu'>
                <li className='mobile-nav__menu-li' onClick={(e) => {
                    handleNavClick(e, '')
                    handleMenuBurger();
                }}><Link to='/about-us'>About Us</Link></li>
                <li className='mobile-nav__menu-li' onClick={(e) => {
                    handleNavClick(e, '')
                    handleMenuBurger();
                }}><Link to='/our-work'>Our Work</Link></li>
                <li className='mobile-nav__menu-li' onClick={(e) => {
                    handleNavClick(e, '')
                    handleMenuBurger();
                }}><Link to='/news-resources'>News & Resources</Link></li>
                <li className='mobile-nav__menu-li' onClick={(e) => {
                    handleNavClick(e, '')
                    handleMenuBurger();
                }}><Link to='/contact'>Contact</Link></li>
            </menu>
            <p className='text-[var(--clr-white)]'>Copyright © 2024 All Right Reserved to WiseCursor, LLC.</p>
        </div>
    )
}

export default MobileNavigation;