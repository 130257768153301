import React, { useEffect, useRef, useState } from 'react';

const FooterDivider = () => {
    const divRef = useRef(null);  // Ref to access the div element
    const [divHeight, setDivHeight] = useState(0);  // State to store the div height

    useEffect(() => {
        // Calculate div height and update the state
        const updateHeight = () => {
            if (divRef.current) {
                setDivHeight(divRef.current.offsetHeight);
            }
        };

        // Initial height calculation
        updateHeight();

        // Update height on window resize
        window.addEventListener('resize', updateHeight);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <div
            className='absolute left-0 h-min w-full'
            ref={divRef}
            style={{ transform: `translateY(-${divHeight - 2}px)` }}
        >
            <svg 
                className='bg-[#ffffff00]' 
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" 
                fillOpacity="1" d="M0,30 C150,70 285,0 432,0 C588,0 726,90 888,90 C1012,90 1188,60 1440,30 L1440,120 L0,120 Z"></path>
            </svg>
        </div>
    );
};

export default FooterDivider;
