import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import ScrollToTop from './hooks/useScroll';
import reportWebVitals from './reportWebVitals';

// import Welcome from './pages/Welcome';
import Home from './pages/Home';
import Homepage from './pages/homepage/Homepage';

import AboutUs from './pages/about-us/AboutUs';
import History from './pages/about-us/History';
import MissionVision from './pages/about-us/MissionVision';
import WaysToGive from './pages/about-us/WaysToGive';
import GetInvolved from './pages/about-us/GetInvolved';
import Donate from './pages/about-us/donate/Donate';

import OurWork from './pages/our-work/OurWork';
import Programs from './pages/our-work/Program';
import ImpactAchievements from './pages/our-work/ImpactAchievements';

import NewsResources from './pages/news/NewsAndResources';
import NewsPage from './pages/news/NewsPage';
import EventsPage from './pages/news/EventsPage';
import ResourcesGuidesPage from './pages/news/ResourcesGuidesPage';
import ResourcesVideosPage from './pages/news/ResourcesVideosPage';
import ResourcesSupportPage from './pages/news/ResourcesSupportPage';
    import ADialogueOnSickleCell from './pages/news/events/ADiologueSickleCell';
    import IconeBrunch from './pages/news/events/IconeBrunch';

import Contact from './pages/contact/Contact';

import TermsConditions from './pages/other/TermsConditions';
import PrivacyPolicy from './pages/other/PrivacyPolicy';
import NoPage from './pages/NoPage';

import './style/modern-normalize.css';
import './style/index.css';
import './style/style.css';
import './style/utils.css';
import ProgramOverview from './pages/our-work/ProgramOverview';
import GenotypeDrive from './pages/news/events/GenotypeDrive';


export const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#330099',
            light: '#330099',
            dark: '#330099',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#00FFBB',
            light: '#00FFBB',
            dark: '#00FFBB',
            contrastText: '#221F20',
        },
        lightBackground: {
            main: '#F0F0F0',
            contrastText: '#221F20',
        },
        darkBackground: {
            main: '#221F20',
            contrastText: '#ffffff',
        }
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 768,
            laptop: 1024,
            desktop: 1440,
        }
    },
});

export default function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {/* <Route path='/welcome' element={ <Welcome /> } /> */}
                <Route path='' element={ <Home /> }>
                    <Route index element={ <Homepage /> } />
                    <Route path='/about-us'>
                        <Route index element={ <AboutUs /> } />
                        <Route path='/about-us/history' element={ <History /> } />
                        <Route path='/about-us/mission-vision' element={ <MissionVision /> } />
                        <Route path='/about-us/ways-to-give' element={ <WaysToGive /> } />
                        <Route path='/about-us/get-involved' element={ <GetInvolved /> } />
                        <Route path='/about-us/donate' element={ <Donate /> } />
                    </Route>
                    <Route path='/our-work'>
                        <Route index element={ <OurWork /> } />
                        <Route path='/our-work/program-overview'>
                            <Route index element={ <ProgramOverview /> } />
                            <Route path='/our-work/program-overview/programs' element={ <Programs /> } />
                        </Route>
                        <Route path='/our-work/impact-and-achievements' element={ <ImpactAchievements /> } />
                    </Route>
                    <Route path='/news-resources'>
                        <Route index element={ <NewsResources /> } />
                        <Route path='/news-resources/news' element={ <NewsPage /> } />
                        <Route path='/news-resources/events' element={ <EventsPage /> } />
                        <Route path='/news-resources/guides' element={ <ResourcesGuidesPage /> } />
                        <Route path='/news-resources/videos' element={ <ResourcesVideosPage /> } />
                        <Route path='/news-resources/support' element={ <ResourcesSupportPage /> } />
                            <Route path='/news-resources/dialogue-sickle-cell' element={ <ADialogueOnSickleCell /> } />
                            <Route path='/news-resources/adialogueonsicklecell' element={<Navigate to="/news-resources/dialogue-sickle-cell" />} />
                            <Route path='/news-resources/icone-brunch' element={ <IconeBrunch /> } />
                            <Route path='/news-resources/genotype-drive' element={ <GenotypeDrive /> } />
                    </Route>
                    <Route path='/contact' element={ <Contact /> } />
                    <Route path='/terms-conditions' element={ <TermsConditions /> } />
                    <Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
                </Route>

                <Route path='*' element={ <Home /> }>
                    <Route index path='*' element={ <NoPage /> } />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
