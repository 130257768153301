import Header from '../components/header';
import { MobileNavProvider } from '../utils/MobileNavContext';
import MobileNavigation from '../components/mobile-navigation';
import Footer from '../components/footer';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../components/breadcrumbs';
import ConstructionAlert from '../components/buttons/constructionAlert';
import { useEffect, useState } from 'react';
import '../style/style-components/gallery.css';
import FooterDivider from '../components/footerDivider';
import Newsletter from '../components/newsletter';


const Home = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    
    return(
        <MobileNavProvider>
            <ConstructionAlert />
            <header className={`Header ${scrolled ? 'scrolled' : ''}`}><Header /></header>
            <div className='MobileNavigation'><MobileNavigation /></div>
            <main>
                <div className='Breadcrumbs block bg-lightbackground'><Breadcrumbs /></div>
                <Outlet />
            </main>


            <Newsletter />            
            <FooterDivider />
            <footer className='Footer bg-[var(--clr-dark-background)] pb-10'><Footer /></footer>
            <ConstructionAlert />
        </MobileNavProvider>
    );
}

export default Home;