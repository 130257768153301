import React from 'react'
import ButtonSecondary from '../buttons/buttonSecondary';
import '../../style/style-pages/news-resources/NewsResources.css';

const EventCard = ({ title = "Title", subtitle = "Subtitle: ", date = "TBA", reservation = "RSVP", description = "Description: ", link = '/', image }) => {
    return (
        <div className="news_events_resources__card news_events_resources__card_lg">
            <div className="news_events_resources__card_content">
                <div className='flex flex-col gap-2'>
                    <h4>{title}</h4>
                    <p className='text-[20px]'>{subtitle}</p>
                    <p className='text-[16px]'>Date: {date}</p>
                    <p className='text-[14px]'>Reservation: {reservation}</p>
                </div>
                <p className='text-[16px]'>{description}</p>
                <div className="news_events_resources__card_btn">
                    <ButtonSecondary link={link} btnText="LEARN MORE" border={true} />
                </div>
            </div>
            <img className='tablet:w-[200px] object-cover rounded-t-2xl tablet:rounded-e-2xl tablet:rounded-s-none' src={image} alt="image_link_missing" />
        </div>
    )
}

export default EventCard;