import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import '../style/style-components/gallery.css';

function importAllImages(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAllImages(require.context('../assets/ADialogueOnSickleCell', false, /\.(png|jpe?g|svg|mp4)$/));

const Gallery = () => {
    return (
        <div className='w-full'>
            <Carousel className='w-[85%] mx-auto' 
                autoPlay 
                interval={5000} 
                infiniteLoop={true} 
                swipeable={false} 
                dynamicHeight={true}
                renderIndicator={false} 
                showThumbs={true}
                thumbWidth={100}
            >
                <div>
                    <img className='slideshow__img' src={images['BM301596.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301693.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301707.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301712.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301603.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301604.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301639.jpg']} alt="" />
                </div>
                {/* <div>
                    <img className='slideshow__img' src={images['BM301690.jpg']} alt="" />
                </div> */}
                <div>
                    <img className='slideshow__img' src={images['BM301736.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301768.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301832.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301869.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301899.jpg']} alt="" />
                </div>
                <div>
                    <img className='slideshow__img' src={images['BM301949.jpg']} alt="" />
                </div>
            </Carousel>
        </div>
    )
}

export default Gallery;