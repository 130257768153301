import React from 'react';
import EventDeftform from '../../components/eventDeftform';
import { Carousel } from 'react-responsive-carousel';
import images from '../../utils/imageLoader';

const History = () => {
    return (
        <>
            <section className='new_page'>
                <div className='history__main flex flex-col gap-8 container'>
                    <div>
                        <h2 className="pb-4">Our History - Eben Family Sickle Cell Foundation</h2>
                    </div>
                    <div className="flex flex-col-reverse laptop:flex-row justify-between gap-8">
                        <div className='flex flex-col justify-start gap-4'>
                            <p className="t4 pb-4">
                                The Eben Family Sickle Cell Foundation (EFSCF) was founded in 2022 by Emmanuel Eben, a passionate advocate for healthcare equality who has personally faced the challenges of living with Sickle Cell Disease (SCD). Witnessing the physical pain, emotional toll, and healthcare disparities associated with the disease, Emmanuel was driven to take action.
                            </p>
                            <p className='t5'>
                                From its inception, the foundation was built on a vision not only to support individuals affected by SCD but also to educate communities, raise awareness, and advocate for better healthcare policies. Starting in Dallas, Texas, the EFSCF quickly expanded its efforts to Cameroon, where the prevalence of SCD is high and resources are limited. The foundation provides essential medical supplies, organizes educational workshops, and creates a community of support for SCD patients and their families.
                            </p>
                            <p className="t5">
                                Dedicated to improving the lives of those affected by SCD, the EFSCF is recognized for its comprehensive approach, addressing the disease through support services, education, advocacy, and global outreach. With a focus on empowering communities and fostering compassion, the foundation honors the legacy of those who inspired its creation and continues to make meaningful strides in the fight against Sickle Cell Disease.
                            </p>
                            <p className="t5">
                                Committed to its mission, the EFSCF works tirelessly to improve the quality of life for individuals with SCD, advocate for better healthcare access, and support research aimed at finding a cure. The foundation’s history is a testament to the power of personal experience driving meaningful change, and its future holds the promise of continued impact and growth.
                            </p>
                            {/* <strong className='font-bold text-[16px] laptop:text-[20px]'>Timeline of Key Milestones:</strong>
                            <ul className="list-disc pl-6">
                                <li>
                                    <p className='t5'>2022: Establishment of the Eben Family Sickle Cell Foundation in Dallas, Texas.</p>
                                </li>
                                <li>
                                    <p className='t5'>2023: Expansion of outreach programs to Cameroon, focusing on education and medical support.</p>
                                </li>
                                <li>
                                    <p className='t5'>2024: Launch of community support initiatives, including a Facebook community group for patients and caregivers.</p>
                                </li>
                                <li>
                                    <p className='t5'>2025: Introduction of new programs focused on genotype testing and medication access in underserved regions.</p>
                                </li>
                            </ul> */}
                        </div>

                        <div>
                            <Carousel className='w-[80vw] laptop:max-w-[384px] desktop:max-w-[608px] mx-auto desktop:mx-0' 
                                autoPlay 
                                interval={10000} 
                                infiniteLoop={true} 
                                swipeable={false} 
                                dynamicHeight={true}
                                renderIndicator={false} 
                                showThumbs={true}
                                thumbWidth={100}
                            >
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default History;