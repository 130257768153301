import React from 'react'
import images from '../../utils/imageLoader';

const AboutUsValues = () => {
    return (
        <div className="about_us__main flex flex-col gap-12 container">
            <div className="about_us__upper">
                <h2 className='pb-4'>About Us</h2>
                <p className='t4'>These values and objectives reflect the Eben Family Sickle Cell Foundation's dedication to improving the lives of those affected by Sickle Cell Disease and driving meaningful change through compassion, innovation, and collaboration.</p>
            </div>
            <div className="about_us__content flex flex-col gap-12">
                <div>
                    {/* <p className='t3 pb-4'>Core Values</p> */}
                    <div className="grid grid-rows-3 grid-cols-1 laptop:grid-cols-2 gap-8">
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values1.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Compassion:</h3>
                                <p className='t5'>We are committed to approaching every aspect of our work with empathy and understanding. We believe in providing care that is both supportive and sensitive to the emotional and physical needs of individuals affected by Sickle Cell Disease.</p>
                            </div>
                        </div>
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values2.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Empowerment:</h3>
                                <p className='t5'>We strive to empower individuals, families, and communities by providing them with the tools, knowledge, and resources necessary to manage Sickle Cell Disease effectively. Through education and support, we enable them to take control of their health and well-being.</p>
                            </div>
                        </div>
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values3.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Integrity</h3>
                                <p className='t5'>We operate with the highest standards of integrity, ensuring transparency, accountability, and honesty in all our actions. Our commitment to ethical practices guides our interactions with patients, partners, and the wider community.</p>
                            </div>
                        </div>
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values4.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Collaboration:</h3>
                                <p className='t5'>We recognize the power of collaboration in achieving our mission. By working together with healthcare providers, researchers, community leaders, and other organizations, we can create a broader impact and foster a supportive network for those affected by Sickle Cell Disease.</p>
                            </div>
                        </div>
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values5.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Innovation:</h3>
                                <p className='t5'>We are dedicated to seeking out and implementing innovative solutions to improve the lives of individuals with Sickle Cell Disease. Whether through new treatments, educational initiatives, or advocacy efforts, we are constantly exploring ways to advance our mission.</p>
                            </div>
                        </div>
                        <div className='about_us__grid_value flex flex-col tablet:flex-row justify-between items-center gap-2 tablet:gap-6'>
                            <div className='relative pb-[56.25%] tablet:pb-0 flex items-center justify-center w-full tablet:w-auto h-min'>
                                <img className='absolute top-0 left-0 w-full h-full tablet:static tablet:min-w-[178px] tablet:max-w-[178px] tablet:max-h-[178px] object-cover rounded-2xl' src={images['AboutUs__Values6.png']} alt="values_icon" />
                            </div>
                            <div>
                                <h3>Diversity and Inclusion:</h3>
                                <p className='t5'>We celebrate diversity and are committed to ensuring that our programs and services are inclusive and accessible to all individuals, regardless of background, culture, or socioeconomic status.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col laptop:flex-row justify-between items-center gap-8'>
                    <img className='laptop:w-1/3 laptop:h-1/3 my-auto' src={images['AboutUs__Objectives.png']} alt="" />
                    <div>
                        <p className="t3">Strategic Objectives</p>
                        <ul className='pt-2 flex flex-col gap-1'>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Expand Access to Healthcare:</strong>
                                <p> Increase access to essential healthcare services, including genotype testing, medication like hydroxyurea, and comprehensive care for individuals with Sickle Cell Disease in underserved regions.</p>
                            </li>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Raise Awareness and Educate:</strong>
                                <p> Develop and implement educational programs to raise awareness about Sickle Cell Disease among patients, caregivers, healthcare providers, and the general public. Focus on early detection, management, and the importance of regular healthcare.</p>
                            </li>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Advocate for Policy Change:</strong>
                                <p> Advocate for better healthcare policies at local, national, and global levels to improve the treatment and care of individuals with Sickle Cell Disease. Push for increased funding for research and the development of new treatments.</p>
                            </li>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Build a Supportive Community:</strong>
                                <p> Create and maintain a strong, supportive community for individuals and families affected by Sickle Cell Disease. Facilitate connections through online platforms, support groups, and community events.</p>
                            </li>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Foster Research and Innovation:</strong>
                                <p> Support and promote research initiatives aimed at finding new treatments and ultimately a cure for Sickle Cell Disease. Collaborate with research institutions, fund studies, and disseminate findings to the community.</p>
                            </li>
                            <li>
                                <strong className='text-[16px] laptop:text-[20px]'>Enhance Global Outreach:</strong>
                                <p> Expand the foundation’s reach to impact more individuals in Africa, particularly in Cameroon, and other regions with high prevalence rates of Sickle Cell Disease. Focus on building sustainable healthcare programs in these areas.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsValues;