import React from 'react'
import ButtonSecondary from '../buttons/buttonSecondary';
import '../../style/style-pages/news-resources/NewsResources.css';

const NewsCard = ({ title = "Title", description = "UPCOMING NEWS", image }) => {
    return (
        <div className="news_events_resources__card news_events_resources__card_md">
            <div className="news_events_resources__card_content">
                <div className='flex flex-col gap-2'>
                    <h4>{title}</h4>
                    <p className='text-[20px]'>{description}</p>
                </div>
                <div className="news_events_resources__card_btn ml-auto">
                    <ButtonSecondary btnText="LEARN MORE" setDisabled={true} border={false} />
                </div>
            </div>
            <img className='tablet:w-[200px] object-cover rounded-t-2xl tablet:rounded-e-2xl tablet:rounded-s-none' src={image} alt="image_link_missing" />
        </div>
    )
}

export default NewsCard;