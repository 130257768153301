import React from 'react'
import ResourcesGuideSection from '../../components/newsEventsResourcesCards/resourcesGuideSection';

const ResourcesGuidesPage = () => {
    return (
        <>
            <section className="new_page">
                <div className="guides__main flex flex-col gap-16 container">
                    <div className="guides__upper">
                        <h2>Guides & Articles</h2>
                        {/* <div className="global__divider" /> */}
                        <p className="t4 pt-2">Join Us in Our Mission to Support the Sickle Cell Community</p>
                        <p className='pt-2'>At the Eben Family Sickle Cell Foundation, we are dedicated to providing comprehensive and accessible information about sickle cell disease. Our educational guides are designed to support patients, families, and healthcare professionals by offering clear, practical resources on living with and managing the condition. Whether you're looking to understand the basics of sickle cell, explore treatment options, or find ways to improve daily living, our guides provide the essential tools and knowledge you need to navigate life with sickle cell disease. Explore our collection and take the first step toward a healthier, more informed future.</p>
                    </div>

                    <div className='news_events_resources__resources'>
                        <div>
                            <h3>Brochures and Guides</h3>
                            <div className="global__divider"></div>
                        </div>
                        <ResourcesGuideSection />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResourcesGuidesPage;