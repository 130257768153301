import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import '../../../style/style-components/gallery.css';
import EventDeftform from '../../../components/eventDeftform';
import images from '../../../utils/imageLoader';
import ButtonSecondary from '../../../components/buttons/buttonSecondary';
import EventCard from '../../../components/newsEventsResourcesCards/eventCard';
// import { Pagination, ThemeProvider } from '@mui/material';
// import { muiTheme } from '../../..';

function importAllImages(r) {
    let images2 = {};
    r.keys().forEach((item, index) => { images2[item.replace('./', '')] = r(item); });
    return images2
}

const images2 = importAllImages(require.context('../../../assets/IconeBrunch', false, /\.(png|jpe?g|svg|mp4)$/));

const IconeBrunch = () => {
    return (
        <>
            <section className='bg-[var(--clr-white)] new_page'>
                <div className="flex flex-col desktop:flex-row justify-between gap-8 container">
                    <div className="video-container w-[90%] mx-auto desktop:mx-0">
                        <div className="relative pb-[56.25%] h-0">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full"
                                src="https://www.youtube.com/embed/Ke-Dm9D1Hzs?autoplay=1&loop=1&rel=0&controls=0"
                                title="Intro Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                                referrerPolicy="strict-origin-when-cross-origin"
                            ></iframe>
                        </div>
                    </div>
                    <div className='max-w-[484px] mx-auto'>
                        <ButtonSecondary link="https://iconebrunch.com" linkNewTab={true} btnText="Visit Icône Brunch" border={true} />
                        <h2 className='py-4'>Event Registration Form</h2>
                        <p className='pb-4'>Let us know how we can help</p>
                        <EventDeftform formId="9571d3e5-3608-4cef-a08b-67ae67444638" />
                    </div>
                </div>
            </section>

            
            <section className='bg-darkbackground text-white'>
                <div className='flex flex-col-reverse desktop:flex-row justify-between gap-16 container'>
                    <div className="flex flex-col gap-6">
                        <h2 className='pb-4'>Icône Brunch</h2>
                        <p className='text-[20px] laptop:text-[24px]'>"We Welcome your Aesthetic"</p>
                        <p className='text-[16px] laptop:text-[20px]'>Join us for the Icône Brunch 2025, a signature event that brings together community leaders, advocates, and supporters to celebrate and advance the mission of the Eben Family Sickle Cell Foundation. This inspiring gathering will feature keynote speakers, networking opportunities, and a shared commitment to making a difference in the fight against Sickle Cell Disease. Save the date and be part of an unforgettable experience as we work together to create a brighter future.</p>
                        <p className='text-[16px] laptop:text-[20px]'>Location: To be announced</p>
                        <p className='text-[16px] laptop:text-[20px]'>Invited Persons to Talk: </p>
                            <ul className='list-none'>
                                <li className='text-[14px] laptop:text-[16px]'></li>
                                <li className='text-[14px] laptop:text-[16px]'></li>
                            </ul>
                        <p className='text-[16px] laptop:text-[20px]'>Date: Fall 2025</p>
                        <p className='text-[16px] laptop:text-[20px]'>Time: TBA</p>
                    </div>
                    <div>
                        <Carousel className='w-[80vw] max-w-[608px] mx-auto desktop:mx-0' 
                            autoPlay 
                            interval={10000} 
                            infiniteLoop={true} 
                            swipeable={false} 
                            dynamicHeight={true}
                            renderIndicator={false} 
                            showThumbs={true}
                            thumbWidth={100}
                        >
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event1.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event2.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event3.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event4.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event5.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event6.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event7.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event8.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event9.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event10.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event11.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event12.png']} alt="" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images2['IconeBrunch__Event13.png']} alt="" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>
            <section>
                <div className="divide-y-[1px] divide-darkbackground flex flex-col gap-4 container">
                    <h2 className='p-2'>Other Events</h2>
                    <div className='pt-6'>
                        <EventCard
                            title='A Dialogue on Sickle Cell' 
                            subtitle='Learn. Connect. Advocate' 
                            date='September 28, 2024' 
                            reservation='Click below to RSVP'
                            description='Join us in Dallas on September 28th during Sickle Cell Awareness Month for an inspiring event with sickle cell warriors! Learn from experts, connect with fellow warriors, and advocate for better research funding, access to care, and policy advancements.'
                            link='/news-resources/dialogue-sickle-cell'
                            image={images['NewsEvents__Card1.png']}
                        />
                    </div>
                </div>
                {/* <div className='pt-16'>
                    <ThemeProvider theme={muiTheme}>
                        <Pagination count={10} color='darkBackground' shape='rounded' sx={{ '& .css-wjh20t-MuiPagination-ul': { justifyContent: 'center' } }} />
                    </ThemeProvider>
                </div> */}
            </section>
        </>
    )
}

export default IconeBrunch;