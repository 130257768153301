import React, { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export const useMobileNav = () => useContext(MenuContext);

export const MobileNavProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuBurger = () => {
    const menuBtn = document.querySelector('.header__burger');
    const mobileNav = document.querySelector('.mobile-nav__screen');
    const styleElem = document.head.appendChild(document.createElement('style'));

    if (!mobileMenuOpen) {
      menuBtn.classList.add('open');
      setMobileMenuOpen(true);
      styleElem.innerHTML = '.header__burger-bars:after {width: 32px;}';
      mobileNav.style.display = 'flex';
      document.body.style.overflowY = 'hidden';
    } else {
      menuBtn.classList.remove('open');
      setMobileMenuOpen(false);
      styleElem.innerHTML = '.header__burger-bars:after {width: 16px;}';
      mobileNav.style.display = 'none';
      document.body.style.overflowY = 'auto';
    }
  };

  return (
    <MenuContext.Provider value={{ mobileMenuOpen, handleMenuBurger }}>
      {children}
    </MenuContext.Provider>
  );
};
