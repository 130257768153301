import React, { useState } from 'react'
import images from '../../utils/imageLoader';
import { muiTheme } from '../..';
import { Pagination, PaginationItem, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventCard from './eventCard';

export const eventCards = [
    { 
        title: "Genotype Drive", 
        subtitle: "Know Your Type: Live Right", 
        date: "October 22 - 23, 2024", 
        reservation: "Click below to view info",
        description: "Join us for the Optimum Health Global (OPHEG) Genotype Drive, health education of genotype and testing 30 randomly selected persons for free testing 100+ persons randomly selected for discounts",
        link: '/news-resources/genotype-drive',
        image: images['Genotype_Drive__Event.jpg'] 
    },
    { 
        title: "A Dialogue on Sickle Cell", 
        subtitle: "Learn. Connect. Advocate", 
        date: "September 28, 2024", 
        reservation: "Click below to RSVP",
        description: "Join us in Dallas on September 28th during Sickle Cell Awareness Month for an inspiring event with sickle cell warriors! Learn from experts, connect with fellow warriors, and advocate for better research funding, access to care, and policy advancements.",
        link: '/news-resources/dialogue-sickle-cell',
        image: images['NewsEvents__Card1.png'] 
    },
    { 
        title: "Icône Brunch", 
        subtitle: "Get the latest information about Icône Brunch",
        date: "Fall 2025",
        description: "Join us for the Icône Brunch 2025, a signature event that brings together community leaders, advocates, and supporters to celebrate and advance the mission of the Eben Family Sickle Cell Foundation.",
        link: '/news-resources/icone-brunch',
        image: images['NewsEvents__Card2.png'] 
    }
];

const EventSection = () => {
    const [page, setPage] = useState(1);
    const cardsPerPage = 2;
    const startIndex = (page - 1) * cardsPerPage;
    const selectedCards = eventCards.slice(startIndex, startIndex + cardsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    return (
        <>
            <div className='news_events_resources__events_content grid grid-rows-1 grid-cols-1 laptop:grid-cols-2 gap-6 py-6'>
                {selectedCards.map((item, index) => (
                    <EventCard 
                        title={item.title}
                        subtitle={item.subtitle}
                        date={item.date}
                        reservation={item.reservation}
                        description={item.description}
                        link={item.link}
                        image={item.image}
                    />
                ))}
            </div>
            <ThemeProvider theme={muiTheme}>
                <Pagination 
                    count={Math.ceil(eventCards.length / cardsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color='darkBackground' 
                    shape='rounded' 
                    sx={{ marginTop: "32px", display: "flex", justifyContent: "center" }} 
                    renderItem={(item) => (
                        <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    )
}

export default EventSection;