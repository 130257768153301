import { Button, ThemeProvider } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { muiTheme } from '../..';

const ButtonSecondary = ({ btnText, link, linkNewTab, sx, border, setDisabled }) => {
    const defaultSx = {
        color: 'var(--clr-dark-background)', fontFamily: 'Amatic SC', fontSize: '24px', fontWeight: '700',
        height: '48px', padding: '8px 16px', borderRadius: '8px',
        ':hover': { backgroundColor: '#00000000', textDecoration: 'underline' },
        ...(border && { border: '1px solid var(--clr-dark-background)' })
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Button 
                variant='text'
                endIcon={<ArrowForwardIcon />}
                LinkComponent={Link} to={link != null ? link : ''} target={linkNewTab != null ? "_blank" : ''} rel="noopener noreferrer"
                sx={sx != null ? [defaultSx, sx] : [defaultSx]}
                disabled={setDisabled}
            >
                {btnText}
            </Button>
        </ThemeProvider>
    )
}

export default ButtonSecondary;