import React from 'react';
import EventDeftform from '../../components/eventDeftform';
import ButtonPrimary from '../../components/buttons/buttonPrimary';
import images from '../../utils/imageLoader';

const WaysToGive = () => {
    return (
        <>
            <section className='new_page'>
                <div className='waystogive__main container'>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col laptop:flex-row justify-between gap-4'>
                            <div className='w-auto'>
                                <h2 className="pb-4">Make a Difference: Donate to Support Our Mission</h2>
                                <p className="t4">Your generosity helps improve the lives of those affected by Sickle Cell Disease.</p>
                            </div>
                            <ButtonPrimary btnText="Join our Mission" link="https://share.deftform.com/j7GXqd" linkNewTab={true} sx={{
                                backgroundColor: 'var(--clr-pantone)', fontSize: { mobile: '20px', desktop: '24px' }, width: '280px', height: '62px', padding: '12px 16px'
                            }} />
                        </div>
                        <div className='flex flex-col laptop:flex-row justify-between gap-8'>
                            <div className="flex flex-col gap-8 laptop:w-full">
                                <div>
                                    <h5 className="pb-2">Introduction</h5>
                                    <p>Your donation plays a crucial role in the work of the Eben Family Sickle Cell Foundation. Every contribution, no matter the size, directly impacts the lives of individuals and families affected by Sickle Cell Disease. Your support enables us to provide essential healthcare services, conduct educational workshops, and advocate for policy changes that improve the quality of life for those living with this challenging condition. Together, we can make a lasting difference in the fight against Sickle Cell Disease.</p>
                                </div>
                                <div>
                                    <h5 className="pb-2">Why Your Donation Matters</h5>
                                    <p>Your donation is a lifeline for the Eben Family Sickle Cell Foundation, directly empowering our mission to support those living with Sickle Cell Disease. Every dollar you contribute helps fund critical healthcare services, educational initiatives, and advocacy efforts that transform lives. By donating, you are not just giving money—you are providing hope, access to vital treatments, and the resources needed to improve the quality of life for individuals and families battling this disease. Your generosity fuels our work and brings us closer to a world where Sickle Cell Disease is better understood, treated, and ultimately, cured.</p>
                                </div>
                            </div>
                            <div className='laptop:w-2/3'>
                                <img className='h-full w-auto laptop:w-full object-contain' src={images['WaysToGive__Img.png']} alt="WaysToGive" />
                            </div>
                        </div>
                        <div>
                            <h3 className="pb-2">Donation Options</h3>
                            <p className='pb-12'>Your donation plays a crucial role in the work of the Eben Family Sickle Cell Foundation. Every contribution, no matter the size, directly impacts the lives of individuals and families affected by Sickle Cell Disease. Your support enables us to provide essential healthcare services, conduct educational workshops, and advocate for policy changes that improve the quality of life for those living with this challenging condition. Together, we can make a lasting difference in the fight against Sickle Cell Disease.</p>
                            <div className='flex flex-col laptop:flex-row justify-between gap-6'>
                                <div className="flex laptop:flex-col gap-2">
                                    <img className='w-[108px] h-[108px]' src={images['Donation__Option1.svg']} alt="Donation Options" />
                                    <div className='flex flex-col gap-2'>
                                        <h5>One-Time Donation</h5>
                                        <p>A one-time donation allows you to make a single contribution to the Eben Family Sickle Cell Foundation, providing immediate support to our ongoing programs and initiatives. Your generosity helps us address urgent needs and fund critical projects that make a difference in the lives of individuals and families affected by Sickle Cell Disease.
                                        <br />Link: Donate Once</p>
                                    </div>
                                </div>
                                <div className="flex laptop:flex-col gap-2">
                                    <img className='w-[108px] h-[108px]' src={images['Donation__Option2.svg']} alt="Donation Options" />
                                    <div className='flex flex-col gap-2'>
                                        <h5>Recurring Donation</h5>
                                        <p>By setting up a recurring donation, you can provide consistent, ongoing support to the foundation. Whether you choose to donate monthly or annually, your recurring gift ensures that we have the resources needed to continue our work throughout the year. This type of donation is particularly impactful as it allows us to plan and execute long-term projects with confidence.
                                        <br />Link: Set Up Recurring Donation</p>
                                    </div>
                                </div>
                                <div className="flex laptop:flex-col gap-2">
                                    <img className='w-[108px] h-[108px]' src={images['Donation__Option3.svg']} alt="Donation Options" />
                                    <div className='flex flex-col gap-2'>
                                        <h5>Major Gifts & Planned Giving</h5>
                                        <p>Major gifts and planned giving are powerful ways to leave a lasting legacy. If you're interested in making a larger contribution or including the Eben Family Sickle Cell Foundation in your estate plans, we offer personalized options to help you achieve your philanthropic goals. These gifts can fund specific initiatives or create endowments that will support our mission for generations to come.
                                        <br />Link: Learn More About Major Gifts & Planned Giving</p>
                                    </div>
                                </div>
                                <div className="flex laptop:flex-col gap-2">
                                    <img className='w-[108px] h-[108px]' src={images['Donation__Option4.svg']} alt="Donation Options" />
                                    <div className='flex flex-col gap-2'>
                                        <h5>In-Kind Donations</h5>
                                        <p>In-kind donations involve giving goods or services instead of money. These contributions are incredibly valuable and can range from medical supplies and office equipment to professional services like legal advice, graphic design, or event planning. In-kind donations help us reduce costs and ensure that more of our financial resources go directly to our programs.
                                        <br />Link: Explore In-Kind Donation Opportunities</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default WaysToGive;