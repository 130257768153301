import React from 'react'
// import EventDeftform from '../../../components/eventDeftform';
import images from '../../../utils/imageLoader';
import EventCard from '../../../components/newsEventsResourcesCards/eventCard';
import HpGallery from '../../homepage/Hp_Gallery';
// import { Pagination, ThemeProvider } from '@mui/material';
// import { muiTheme } from '../../..';

const ADialogueOnSickleCell = () => {
    return (
        <>
            <section className='bg-[var(--clr-white)] new_page'>
                <div className='flex flex-col gap-8 container'>
                    <HpGallery />
                    <div className="relative pb-[56.25%] h-0">
                        <iframe
                            className="absolute top-0 left-0 w-full h-full"
                            src="https://www.youtube.com/embed/XZHU0doAK1o?autoplay=1&loop=1&rel=0&controls=0"
                            title="Intro Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                            referrerPolicy="strict-origin-when-cross-origin"
                        ></iframe>
                    </div>
                </div>


                {/* <div className="flex flex-col desktop:flex-row justify-between gap-8 container">
                    <div className="video-container w-[90%] mx-auto desktop:mx-0">
                        <div className="relative pb-[56.25%] h-0">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full"
                                src="https://www.youtube.com/embed/XZHU0doAK1o?autoplay=1&loop=1&rel=0&controls=0"
                                title="Intro Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                                referrerPolicy="strict-origin-when-cross-origin"
                            ></iframe>
                        </div>
                    </div>

                    <div className='max-w-[484px] mx-auto'>
                        <h2 className='pb-4'>Event Registration Form</h2>
                        <p className='pb-4'>Let us know how we can help</p>
                        <EventDeftform formId="13248c9a-3157-4c42-830f-ffd5708e91c8" />
                    </div>
                </div> */}
            </section>
            <section className='bg-darkbackground text-white'>
                <div className='flex flex-col-reverse desktop:flex-row justify-between gap-8 container'>
                    <div className="flex flex-col gap-6">
                        <h2 className='pb-4'>A Dialogue on Sickle Cell</h2>
                        <p className='text-[20px] laptop:text-[24px]'>Learn. Connect. Advocate.</p>
                        <p className='text-[16px] laptop:text-[20px]'>Join us in Dallas on September 28th during Sickle Cell Awareness Month for an inspiring event with sickle cell warriors! Learn from experts, connect with fellow warriors, and advocate for better research funding, access to care, and policy advancements.</p>
                        <p className='text-[16px] laptop:text-[20px]'>Location: Cedar Crest Community Center <br />1007 Hutchins Rd, Dallas, TX 75203</p>
                        <p className='text-[16px] laptop:text-[20px]'>Invited Persons to Talk: </p>
                            <ul className='list-none'>
                                <li className='text-[14px] laptop:text-[16px]'>Dr. Alecia Nero, MD, MSCS, FACP</li>
                                <li className='text-[14px] laptop:text-[16px]'>Dr. Kabir O. Olaniran, MD, MPH</li>
                            </ul>
                        <p className='text-[16px] laptop:text-[20px]'>Date: September 28, 2024</p>
                        <p className='text-[16px] laptop:text-[20px]'>Time: 2:00 PM - 6:00 PM</p>
                    </div>
                    <img className='mx-auto desktop:mx-0 w-[80vw] max-w-[608px]' src={images['NewsEvents__Card1.png']} alt="" />
                </div>
            </section>
            <section>
                <div className="divide-y-[1px] divide-darkbackground flex flex-col gap-4 container">
                    <h2 className='p-2'>Other Events</h2>
                    <div className='pt-6'>
                        <EventCard
                            title='Icône Brunch'
                            subtitle='Stay tuned and sign up to get latest information about next icôneBrunch.'
                            date='Fall 2025'
                            description='Join us for the Icône Brunch 2025, a signature event that brings together community leaders, advocates, and supporters to celebrate and advance the mission of the Eben Family Sickle Cell Foundation.'
                            link='/news-resources/icone-brunch'
                            image={images['NewsEvents__Card2.png']}
                        />
                    </div>
                </div>
                
                {/* <div className='pt-16'>
                    <ThemeProvider theme={muiTheme}>
                        <Pagination count={10} color='darkBackground' shape='rounded' sx={{ '& .css-wjh20t-MuiPagination-ul': { justifyContent: 'center' } }} />
                    </ThemeProvider>
                </div> */}
            </section>
        </>
    )
}

export default ADialogueOnSickleCell;
