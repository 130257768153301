import React from 'react'
import ResourcesSupportSection from '../../components/newsEventsResourcesCards/resourcesSupportSection';

const ResourcesSupportPage = () => {
    return (
        <>
            <section className="new_page">
                <div className="support__main flex flex-col gap-16 container">
                    <div className="support__upper">
                        <h2>Patient Support</h2>
                        {/* <div className="global__divider" /> */}
                        <p className="t4 pt-2">Join Us in Our Mission to Support the Sickle Cell Community</p>
                        <p className='pt-2'>Multiple sources of patient support outreach provided for patients of Sickle Cell</p>
                    </div>

                    <div className='news_events_resources__resources'>
                        <div>
                            <h3>Patient Support</h3>
                            <div className="global__divider"></div>
                        </div>
                        <ResourcesSupportSection />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResourcesSupportPage;