import React, { useState } from 'react'
import { muiTheme } from '../..';
import { Pagination, PaginationItem, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ResourcesCard from './resourcesCard';

const ResourcesGuideSection = () => {
    const [page, setPage] = useState(1);
    const cardsPerPage = 3;
    
    const resourcesCards = [
        { 
            title: "Understanding Sickle Cell Disease",
            description: "Dowloadable PDF explaining the basics of sickle cell disease, its symptoms and treatment options.",
            link: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:08190163-6f6a-4616-bf19-0deb75055e9a'
        },
        { 
            title: "Sickle Cell Disease Prevention and Treatment",
            description: "Practical tips and strategies for patients and families to manage the condition effectively at home.",
            link: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:b6793f8d-dd58-4c13-b4ef-373d9d1c32c3'
        },
        { 
            title: "Nutrition for Sickle Cell",
            description: "Information on dietary choices that can help manage sickle cell symptoms.",
            link: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:d707df78-8422-4fdb-9942-4e2152628061'
        }
    ];

    const startIndex = (page - 1) * cardsPerPage;
    const selectedCards = resourcesCards.slice(startIndex, startIndex + cardsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    
    return (
        <>
            <div className='news_events_resources__guides_content grid grid-rows-1 grid-cols-1 laptop:grid-cols-3 gap-6 py-6'>
                {selectedCards.map((item, index) => (
                    <ResourcesCard 
                        title={item.title}
                        description={item.description}
                        link={item.link}
                    />
                ))}
            </div>
            <ThemeProvider theme={muiTheme}>
                <Pagination 
                    count={Math.ceil(resourcesCards.length / cardsPerPage)}
                    page={page}
                    onChange={handleChange}
                    color='darkBackground' 
                    shape='rounded' 
                    sx={{ marginTop: "32px", display: "flex", justifyContent: "center" }} 
                    renderItem={(item) => (
                        <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    )
}

export default ResourcesGuideSection;