import React from 'react'
import EventDeftform from '../../components/eventDeftform';
import Statistics from '../homepage/Statistics';
// import Testimonials from './Testimonials';
import images from '../../utils/imageLoader';
import '../../style/style-components/gallery.css';
import { Carousel } from 'react-responsive-carousel';
import ButtonPrimary from '../../components/buttons/buttonPrimary';

const OurWork = () => {
    return (
        <>
            <section className="new_page">
                <div className="our_work__main flex flex-col laptop:flex-row justify-between gap-16 laptop:gap-8 container">
                    <div className='flex flex-col gap-12'>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-4">
                                <h2>Our Work</h2>
                                <p className="t4">Making an Impact in the Fight Against Sickle Cell Disease</p>
                            </div>
                            <p className="t5">Our mission is to improve lives, provide essential healthcare and empower communities.</p>
                            <p>The Eben Family Sickle Cell Foundation is dedicated to improving the lives of individuals affected by Sickle Cell Disease. Our work spans multiple areas, including expanding access to essential healthcare services, providing educational programs for patients and communities, and advocating for stronger healthcare policies to enhance treatment and care. Through our global outreach initiatives, we work to bring vital support to underserved regions, particularly in Africa and the U.S., while fostering innovation and research for a better future. Together, we aim to empower those impacted by Sickle Cell Disease and drive meaningful change on a global scale.</p>
                            <ul className="list-disc pl-6">
                                <li><p><strong>Genotype Testing and Screening: </strong>Explanation of how the foundation provides access to genotype testing for early detection and management of Sickle Cell Disease.</p></li>
                                <li><p><strong>Healthcare Services: </strong>Information on medical treatment, medication distribution (like hydroxyurea), and patient care provided through clinics and partnerships.</p></li>
                                <li><p><strong>Educational Workshops: </strong>Details on community education initiatives, focusing on raising awareness about Sickle Cell Disease and empowering patients and healthcare providers.</p></li>
                                <li><p><strong>Community Support Programs: </strong>Support groups, mental health resources, and peer counseling for patients and their families.</p></li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-4">
                            <h2>Our Programs</h2>
                            <p>The Eben Family Sickle Cell Foundation offers a range of programs designed to improve the lives of those affected by Sickle Cell Disease. Our initiatives focus on key areas including access to healthcare, education, and advocacy. We provide genotype testing and medication access, ensuring that patients receive the medical care they need. Through educational workshops, we empower both individuals and communities with knowledge about Sickle Cell Disease. Additionally, we work to build a supportive community, offering peer support and counseling for patients and families. By advocating for better healthcare policies and fostering research and innovation, our programs strive to make a lasting impact locally and globally.</p>
                        </div>
                        <div className='ml-auto'>
                            <ButtonPrimary btnText="Join our Mission" link="https://share.deftform.com/j7GXqd" linkNewTab={true} sx={{
                                backgroundColor: 'var(--clr-pantone)', height: '62px', padding: '12px 16px'
                            }} />
                        </div>
                    </div>

                    <div>
                        <Carousel className='w-[80vw] max-w-[608px] mx-auto desktop:mx-0' 
                            autoPlay 
                            interval={10000} 
                            infiniteLoop={true} 
                            swipeable={false} 
                            dynamicHeight={true}
                            renderIndicator={false} 
                            showThumbs={true}
                            thumbWidth={100}
                        >
                            <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro2.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro3.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro4.png']} alt="gallery_content" />
                                </div>
                                <div>
                                    <img className='slideshow__img' src={images['History__Intro1.png']} alt="gallery_content" />
                                </div>
                        </Carousel>
                    </div>
                </div>
            </section>
            

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,30 C150,70 285,0 432,0 C588,0 726,90 888,90 C1012,90 1188,60 1440,100 L1440,120 L0,120 Z"></path></svg>
            <section className='Intro bg-[var(--clr-dark-background)] text-[var(--clr-white)]'><Statistics /></section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><path fill="var(--clr-dark-background)" fillOpacity="1" d="M0,20 C150,50 285,100 432,100 C588,100 726,10 888,10 C1012,10 1188,40 1440,100 L1440,0 L0,0 Z"></path></svg>


            <section>
                <div className="our_programs__main flex flex-col gap-4 container">
                    <h2>Our Programs</h2>
                    <div className="flex flex-col justify-between gap-6">
                        <div className="flex flex-col laptop:flex-row gap-4">
                            <div className='max-w-[200px] w-full my-auto'>
                                <img className='w-full h-full object-contain rounded-2xl' src={images['OurWork__Programs1.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-6 flex-grow-0">
                                <h3>Where We Work</h3>
                                <p className='t5'>Regional Impact: Detail the specific regions where the foundation operates, such as Cameroon and Dallas, Texas. Explain the unique challenges faced in these areas and how the foundation is addressing them.
                                Global Initiatives: Highlight international outreach efforts, including partnerships and projects aimed at expanding healthcare access and education beyond local regions.</p>
                            </div>
                        </div>
                        <div className="flex flex-col laptop:flex-row-reverse gap-4">
                            <div className='max-w-[200px] w-full my-auto'>
                                <img className='w-full h-full object-contain rounded-2xl' src={images['OurWork__Programs2.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-6 flex-grow-0">
                                <h3>Research and Innovation</h3>
                                <p className='t5'>Description: Provide information on the foundation’s involvement in research efforts to find new treatments and solutions for Sickle Cell Disease.
                                Current Research Projects: List any ongoing or completed research initiatives, collaborations with universities or medical centers, and the impact of these projects on patient care.
                                Innovative Approaches: Include details on innovative methods the foundation is using, such as integrating technology in healthcare delivery or using data to improve outcomes.</p>
                            </div>
                        </div>
                        <div className="flex flex-col laptop:flex-row gap-4">
                            <div className='max-w-[200px] w-full my-auto'>
                                <img className='w-full h-full object-contain rounded-2xl' src={images['OurWork__Programs3.png']} alt="" />
                            </div>
                            <div className="flex flex-col gap-6 flex-grow-0">
                                <h3>Advocating for Change</h3>
                                <p className='t5'>Description: Explain the foundation’s work in advocating for better healthcare policies and funding for Sickle Cell Disease research.
                                Current Campaigns: Detail ongoing advocacy efforts, such as petitions, meetings with policymakers, or public campaigns to raise awareness and influence policy change.
                                How to Get Involved: Provide a call-to-action for visitors to participate in advocacy efforts, including links to petitions, information on contacting legislators, and upcoming events.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section>
                <Testimonials />
            </section> */}


            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default OurWork;