import React from 'react'
import images from '../../utils/imageLoader';
import '../../style/style-pages/homepage/IntroCards.css';
import ButtonSecondary from '../../components/buttons/buttonSecondary';

const IntroCards = () => {
    return (
        <div className='intro__main container'>
            <div className="intro__upper">
                <h2 className='pb-4'>Meet Our Foundation</h2>
                <p className='text-[20px] laptop:text-[24px]'>Empowering Lives, One Step at a Time.</p>
            </div>
            <div className="intro__cards grid grid-flow-row grid-cols-1 laptop:grid-cols-3 gap-6 py-8">
                <div className="intro__card group hover:shadow-[4px_8px_16px_-2px_rgba(0,0,0,0.25)] rounded-2xl">
                    <img className=' w-full grayscale group-hover:grayscale-0 transition duration-300' src={images['IntroCards__Card1.png']} alt="intro card" />
                    <div className="intro__card_content bg-[var(--clr-violet)] text-white rounded-b-2xl gap-4">
                        <div className='flex flex-col gap-2'>
                            <h4>Introduction to the Foundation</h4>
                            <p className='t5'>Brief Overview:</p>
                            <p>
                                At the Eben Family Sickle Cell Foundation, our mission is to provide comprehensive support, education, and resources to individuals and families affected by sickle cell disease. We strive to improve quality of life through advocacy, awareness, and compassionate care.
                            </p>
                        </div>
                        <div className="ml-auto">
                            <ButtonSecondary btnText="Discover our Mission and Vission" link="/about-us/mission-vision" sx={{ 
                                color: 'white', border: '1px solid var(--clr-white)', fontSize: { mobile: '24px', laptop: '18px', desktop: '24px' } 
                            }} />
                        </div>
                    </div>
                </div>
                <div className="intro__card group hover:shadow-[4px_8px_16px_-2px_rgba(0,0,0,0.25)] rounded-2xl">
                    <img className=' w-full grayscale group-hover:grayscale-0 transition duration-300' src={images['IntroCards__Card2.png']} alt="intro card" />
                    <div className="intro__card_content bg-[var(--clr-pantone)] text-white rounded-b-2xl gap-4">
                        <div className='flex flex-col gap-2'>
                            <h4>Our Programs and Impact</h4>
                            <p className='t5'>Programs Overview:</p>
                            <ul className="list-disc pl-6">
                                <li>
                                    <p>
                                        <strong>Support Services:</strong> Providing patients and families with essential resources, emotional support, and access to necessary treatments, including the medication hydroxyurea for managing sickle cell disease.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Educational Workshops:</strong> Offering workshops to educate the community and healthcare providers about sickle cell disease, including the importance of genotype testing for early detection and management.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Advocacy:</strong> Advocating for better healthcare policies, increased funding for sickle cell research, and promoting the availability of essential medications like hydroxyurea.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="ml-auto">
                            <ButtonSecondary btnText="Explore Our Work" link="/our-work" sx={{ 
                                color: 'white', border: '1px solid var(--clr-white)', fontSize: { mobile: '24px', laptop: '18px', desktop: '24px' } 
                            }} />
                        </div>
                    </div>
                </div>
                <div className="intro__card group hover:shadow-[4px_8px_16px_-2px_rgba(0,0,0,0.25)] rounded-2xl">
                    <img className=' w-full grayscale group-hover:grayscale-0 transition duration-300' src={images['IntroCards__Card3.png']} alt="intro card" />
                    <div className="intro__card_content bg-[var(--clr-honolulu)] text-white rounded-b-2xl gap-4">
                        <div className='flex flex-col gap-2'>
                            <h4>How You Can Help</h4>
                            <p className='t5'>Ways to Give:</p>
                            <p>Your generous donations help us provide critical support and resources to the sickle cell community. Every contribution makes a significant impact. <br /><br />
                                Join our team of volunteers and help make a difference for those affected by sickle cell disease. Whether you’re organizing events, providing support, or assisting patients, your help matters.</p>
                        </div>
                        <div className="flex justify-between">
                            <ButtonSecondary link="/about-us/donate" btnText="Donate Now" sx={{ 
                                color: 'white', border: '1px solid var(--clr-white)', fontSize: { mobile: '24px', laptop: '18px', desktop: '24px' } 
                            }} />
                            <ButtonSecondary btnText="Volunteer" link="/about-us/get-involved" sx={{ 
                                color: 'white', border: '1px solid var(--clr-white)', fontSize: { mobile: '24px', laptop: '18px', desktop: '24px' } 
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroCards;