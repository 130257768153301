export const handleNavClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
        const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - 106;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth',
        });
    }
};
