import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../style/style-pages/homepage/Hero.css';
import { Carousel } from 'react-responsive-carousel';
import '../../style/style-components/gallery.css';
import images from '../../utils/imageLoader';
import backgroundVideo from '../../assets/Hero__Background1.mp4';
import backgroundVideo2 from '../../assets/Hero__Background4.mp4';
import ButtonPrimary from '../../components/buttons/buttonPrimary';

const Hero = () => {
  return (
    <div className='hero__main flex flex-col justify-center'>
        <Carousel 
            autoPlay 
            interval={10000} 
            dynamicHeight={true} 
            infiniteLoop={true} 
            swipeable={false}
            showThumbs={false}
            showStatus={false}
        >
            {/* First Image */}
            <div>
                <div className="hero__content flex flex-col max-w-[1196px] gap-6 laptop:gap-12">
                    <div>
                        <h1 className='text-center text-[40px] laptop:text-[64px]'>Transforming Lives Through Support and Education</h1>
                        <p className='hidden laptop:inline laptop:text-[32px] laptop:text-left'>Join us in our mission to support and improve the lives of those affected by Sickle Cell Disease. Together, we can make a difference.</p>
                        <p className='text-[24px] text-center laptop:hidden'>Join us in empowering the Sickle Cell community</p>
                    </div>
                    <div className='m-auto'>
                        <ButtonPrimary link="/news-resources/dialogue-sickle-cell" btnText="Learn About this Event" sx={{ fontSize: { mobile: '18px', laptop: '20px' }, height: '46px', fontWeight: '400' }} />
                    </div>
                </div>
                <video className='min-w-full h-[88vh] object-cover' autoPlay muted playsInline loop>
                    <source src={backgroundVideo} type='video/mp4' />
                </video>
            </div>
            {/* Second Image */}
            <div>
                <div className="hero__content flex flex-col max-w-[1196px] gap-6 laptop:gap-12">
                    <div>
                        <h1 className='text-center text-[40px] laptop:text-[64px]'>Hope and Healing for Sickle Cell Warriors</h1>
                        <p className='hidden laptop:inline laptop:text-[32px] laptop:text-left'>Dedicated to providing support, resources, and hope to individuals and families battling Sickle Cell Disease.</p>
                        <p className='text-[24px] text-center laptop:hidden'>Join us in empowering the Sickle Cell community</p>
                    </div>
                    <div className='m-auto'>
                        <ButtonPrimary link="/about-us" btnText="Learn More About Us" sx={{ fontSize: { mobile: '18px', laptop: '20px' }, height: '46px', fontWeight: '400' }} />
                    </div>
                </div>
                <img className='min-w-full h-[88vh] object-cover' src={images['Hero__Background2.jpg']} alt='hero background' />
            </div>
            {/* Third Image */}
            <div>
                <div className="hero__content flex flex-col max-w-[1196px] gap-6 laptop:gap-12">
                    <div>
                        <h1 className='text-center text-[40px] laptop:text-[64px]'>Championing the Fight Against Sickle Cell Disease</h1>
                        <p className='hidden laptop:inline laptop:text-[32px] laptop:text-left'>Your support helps us drive innovative research, raise awareness, and provide critical care to those in need.</p>
                        <p className='text-[24px] text-center laptop:hidden'>Join us in empowering the Sickle Cell community</p>
                    </div>
                    <div className='m-auto'>
                        <ButtonPrimary link="/about-us" btnText="Learn More About Us" sx={{ fontSize: { mobile: '18px', laptop: '20px' }, height: '46px', fontWeight: '400' }} />
                    </div>
                </div>
                <img className='min-w-full h-[88vh] object-cover desktop:object-fill' src={images['Hero__Background3.jpg']} alt='hero background' />
            </div>
            {/* Fourth Image */}
            <div>
                <div className="hero__content flex flex-col max-w-[1196px] gap-6 laptop:gap-12">
                    <div>
                        <h1 className='text-center text-[40px] laptop:text-[64px]'>Strengthening Communities, One Life at a Time</h1>
                        <p className='hidden laptop:inline laptop:text-[32px] laptop:text-left'>Through advocacy, education, and research, we aim to enhance the quality of life for Sickle Cell patients worldwide.</p>
                        <p className='text-[24px] text-center laptop:hidden'>Join us in empowering the Sickle Cell community</p>
                    </div>
                    <div className='m-auto'>
                        <ButtonPrimary link="/news-resources/icone-brunch" btnText="Learn About this Event" sx={{ fontSize: { mobile: '18px', laptop: '20px' }, height: '46px', fontWeight: '400' }} />
                    </div>
                </div>
                <video className='min-w-full h-[88vh] object-cover' autoPlay muted playsInline loop>
                    <source src={backgroundVideo2} type='video/mp4' />
                </video>            
            </div>
        </Carousel>
    </div>
  )
}

export default Hero;