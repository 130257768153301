import React from 'react'
import images from '../../utils/imageLoader';
import EventDeftform from '../../components/eventDeftform';
import { Carousel } from 'react-responsive-carousel';
import ButtonPrimary from '../../components/buttons/buttonPrimary';

const GetInvolved = () => {
    return (
        <>
            <section className='new_page'>
                <div className="volunteer__main flex flex-col gap-8 container">
                    <div className='flex justify-between items-center gap-8'>
                        <div>
                            <h2 className="pb-4">Join Us: Get Involved and Make an Impact</h2>
                            <p className="t4">Your support is crucial to our mission. Learn how you can contribute.</p>
                        </div>
                        <div>
                            <ButtonPrimary btnText="Join our Mission" link="https://share.deftform.com/j7GXqd" linkNewTab={true} sx={{
                                backgroundColor: 'var(--clr-pantone)', height: '62px', padding: '12px 16px'
                            }} />
                        </div>
                    </div>
                    <Carousel className='mx-auto' 
                        autoPlay 
                        interval={10000} 
                        infiniteLoop={true} 
                        swipeable={false} 
                        dynamicHeight={true}
                        showThumbs={false}
                        thumbWidth={100}
                    >
                        <div>
                            <img className='grayscale' src={images['Hero__Background2.jpg']} alt="Carousel_slideshow" />
                        </div>
                        <div>
                            <img className='grayscale' src={images['Hero__Background2.jpg']} alt="Carousel_slideshow" />
                        </div>
                        <div>
                            <img className='grayscale' src={images['Hero__Background2.jpg']} alt="Carousel_slideshow" />
                        </div>
                        <div>
                            <img className='grayscale' src={images['Hero__Background2.jpg']} alt="Carousel_slideshow" />
                        </div>
                    </Carousel>
                    <div>
                        <h2 className="pb-2">Volunteer Opportunities</h2>
                        <p className="t4">Volunteers are the backbone of the Eben Family Sickle Cell Foundation, playing a critical role in driving our mission forward. Their dedication, passion, and commitment allow us to extend our reach and make a greater impact in the lives of those affected by Sickle Cell Disease. From organizing events and providing administrative support to offering direct assistance to patients, volunteers enable us to continue our work with greater efficiency and effectiveness. Every hour of time donated by our volunteers helps us bring hope, education, and care to those who need it most. Your involvement as a volunteer is not just valuable—it is essential to the success of our foundation and the well-being of our community.</p>
                    </div>
                </div>
                <div className='volunteer__main flex flex-col justify-between gap-8 pt-16 container'>
                    <div>
                        <h3 className="pb-2">Types of Volunteer Roles:</h3>
                        <ul className='list-disc pl-6'>
                            <li><p>Event Volunteers: Description of roles available during events like fundraising galas or community outreach.</p></li>
                            <li><p>Office Support: Roles like administrative help or social media management.</p></li>
                            <li><p>Community Outreach: Opportunities to engage directly with communities affected by Sickle Cell Disease.</p></li>
                        </ul>
                    </div>
                    <div className='grid grid-rows-6 grid-cols-1 laptop:grid-rows-3 laptop:grid-cols-2 gap-6'>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Volunteer__Option1.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Join a Support Group</h5>
                                <p>A one-time donation allows you to make a single contribution to the Eben Family Sickle Cell Foundation, providing immediate support to our ongoing programs and initiatives. Your generosity helps us address urgent needs and fund critical projects that make a difference in the lives of individuals and families affected by Sickle Cell Disease.
                                <br />Link: Donate Once</p>
                            </div>
                        </div>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Donation__Option4.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>In-Kind Donations</h5>
                                <p>In-kind donations involve giving goods or services instead of money. These contributions are incredibly valuable and can range from medical supplies and office equipment to professional services like legal advice, graphic design, or event planning. In-kind donations help us reduce costs and ensure that more of our financial resources go directly to our programs.
                                <br />Link: Explore In-Kind Donation Opportunities</p>
                            </div>
                        </div>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Volunteer__Option4.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Advocate for Policy Change</h5>
                                <p>In-kind donations involve giving goods or services instead of money. These contributions are incredibly valuable and can range from medical supplies and office equipment to professional services like legal advice, graphic design, or event planning. In-kind donations help us reduce costs and ensure that more of our financial resources go directly to our programs.
                                <br />Link: Explore In-Kind Donation Opportunities</p>
                            </div>
                        </div>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Volunteer__Option2.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Fundraising and Events</h5>
                                <p>By setting up a recurring donation, you can provide consistent, ongoing support to the foundation. Whether you choose to donate monthly or annually, your recurring gift ensures that we have the resources needed to continue our work throughout the year. This type of donation is particularly impactful as it allows us to plan and execute long-term projects with confidence.
                                <br />Link: Set Up Recurring Donation</p>
                            </div>
                        </div>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Volunteer__Option3.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Spread the Word</h5>
                                <p>Major gifts and planned giving are powerful ways to leave a lasting legacy. If you're interested in making a larger contribution or including the Eben Family Sickle Cell Foundation in your estate plans, we offer personalized options to help you achieve your philanthropic goals. These gifts can fund specific initiatives or create endowments that will support our mission for generations to come.
                                <br />Link: Learn More About Major Gifts & Planned Giving</p>
                            </div>
                        </div>
                        <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Volunteer__Option4.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Corporate Partnerships</h5>
                                <p>In-kind donations involve giving goods or services instead of money. These contributions are incredibly valuable and can range from medical supplies and office equipment to professional services like legal advice, graphic design, or event planning. In-kind donations help us reduce costs and ensure that more of our financial resources go directly to our programs.
                                <br />Link: Explore In-Kind Donation Opportunities</p>
                            </div>
                        </div>
                        {/* <div className="flex laptop:flex-col gap-2">
                            <img className='w-[108px] h-[108px]' src={images['Donation__Option3.svg']} alt="Donation Options" />
                            <div className='flex flex-col gap-2'>
                                <h5>Major Gifts & Planned Giving</h5>
                                <p>In-kind donations involve giving goods or services instead of money. These contributions are incredibly valuable and can range from medical supplies and office equipment to professional services like legal advice, graphic design, or event planning. In-kind donations help us reduce costs and ensure that more of our financial resources go directly to our programs.
                                <br />Link: Explore In-Kind Donation Opportunities</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            
            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default GetInvolved;