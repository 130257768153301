import React, { useState } from 'react';
import EventDeftform from '../../../components/eventDeftform';
import { ThemeProvider } from '@emotion/react';
import { muiTheme } from '../../..';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import images from '../../../utils/imageLoader';

const Donate = () => {
    const [donationType, setDonationType] = useState("Monthly Donation");
    
    const handleDonation = (event, donationType) => {
        setDonationType(donationType);
    }

  return (
    <>
        <section className='p-0'>
            <div className="donate__main relative">
                <div className='w-screen h-[30vh] laptop:h-[80vh]'>
                    <img className='w-full h-full object-cover' src={images['Donation__Background.png']} alt="donation background" />
                </div>
                <div className="laptop:absolute laptop:top-1/2 laptop:left-2/3 laptop:translate-x-[-50%] laptop:translate-y-[-50%] glass-morphism 
                    rounded-2xl flex flex-col gap-8 laptop:min-w-[580px] laptop:w-[60vw] laptop:max-w-[1072px] laptop:max-h-[70vh] py-8 px-6"
                    >
                    <div className="donate__upper flex flex-col gap-4">
                        <h2>Donate Today, Change Lives Tomorrow</h2>
                        <strong className='text-[20] laptop:text-[24px]'>Your Support Brings Hope to Those Battling Sickle Cell Disease</strong>
                        <p className="t5 hidden desktop:block">
                            At the Eben Family Sickle Cell Foundation, your donations directly impact the lives of individuals and families affected by Sickle Cell Disease. With your generosity, we can provide vital healthcare services, life-saving medication, and educational programs that empower patients and their communities. Every contribution brings us one step closer to improving healthcare access, fostering research, and creating a world where no one suffers from Sickle Cell Disease alone. Your donation makes a difference—today, tomorrow, and for generations to come.
                        </p>
                    </div>
                    <div className="donate__content flex flex-col gap-8">
                        <ThemeProvider theme={muiTheme}>
                            <ToggleButtonGroup
                                value={donationType}
                                exclusive
                                onChange={handleDonation}
                                aria-label='donation type'
                                sx={{ '& .css-1grv7fy-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': { color: 'white', backgroundColor: 'black' },
                                        '& .css-1grv7fy-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover': { color: 'white', backgroundColor: 'black' } }}
                            >
                                <ToggleButton value="Monthly Donation" aria-label='monthly donation' sx={{ 
                                    width: '50%', padding: '8px 16px', font: 'roboto', textTransform: 'none', lineHeight: '1.5', fontSize: { mobile: '14px', laptop: '20px', desktop: '24px' }
                                }}>Monthly Donation</ToggleButton>
                                <ToggleButton value="One-Time Donation" aria-label='monthly donation' sx={{ 
                                    width: '50%', padding: '8px 16px', font: 'roboto', textTransform: 'none', lineHeight: '1.5', fontSize: { mobile: '14px', laptop: '20px', desktop: '24px' }
                                }}>One-Time Donation</ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>
                        <div className='donate__stripe grid grid-rows-4 grid-cols-1 tablet:grid-rows-1 tablet:grid-cols-4 gap-4 mx-auto'>
                            {donationType === "Monthly Donation" && (
                                <>
                                    <Link to='https://buy.stripe.com/aEU3e3aaLeNQbZKaEI' target='__blank'>
                                        <img src={images['Donation__Card1.png']} alt="donation $5" />
                                    </Link>
                                    <Link to='https://buy.stripe.com/aEUdSHciTaxA8Ny28b' target='__blank'>
                                        <img src={images['Donation__Card2.png']} alt="donation $5" />
                                    </Link>
                                    <Link to='https://buy.stripe.com/14k5mb1Ef8ps9RCbIQ' target='__blank'>
                                        <img src={images['Donation__Card3.png']} alt="donation $5" />
                                    </Link>
                                    <Link to='https://buy.stripe.com/9AQ4i72Ij9tw0h214d' target='__blank'>
                                        <img src={images['Donation__Card4.png']} alt="donation $5" />
                                    </Link>
                                </>
                            )}
                            {donationType === "One-Time Donation" && (
                                <>
                                <Link to='https://buy.stripe.com/3cs5mbaaL6hk9RCbIO' target='__blank'>
                                    <img src={images['Donation__CardB_1.png']} alt="donation $5" />
                                </Link>
                                <Link to='https://buy.stripe.com/7sI15VbeP8psaVGeV4' target='__blank'>
                                    <img src={images['Donation__CardB_2.png']} alt="donation $5" />
                                </Link>
                                <Link to='https://buy.stripe.com/4gwdSHdmXcFIe7S8wH' target='__blank'>
                                    <img src={images['Donation__CardB_3.png']} alt="donation $5" />
                                </Link>
                                <Link to='https://donate.stripe.com/5kA3e3ciT49c5BmaEG' target='__blank'>
                                    <img src={images['Donation__CardB_4.png']} alt="donation $5" />
                                </Link>
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                <div>
                    <h2 className='pb-4'>GET IN TOUCH</h2>
                    <p className='t4'>Let us know how we can help</p>
                </div>
                
                <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
            </div>
        </section>
    </>
  )
}

export default Donate;