import React from 'react'
import NewsSection from '../../components/newsEventsResourcesCards/newsSection';

const NewsPage = () => {
    return (
        <>
            <section className="new_page">
                <div className="news__main flex flex-col gap-16 container">
                    <div className="news__upper">
                        <h2>Eben Family News</h2>
                        {/* <div className="global__divider" /> */}
                        <p className="t4 pt-2">Join Us in Our Mission to Support the Sickle Cell Community</p>
                        <p className='pt-2'>Welcome to our News page, where you can stay informed about the latest activities of the Eben Family Sickle Cell Foundation.</p>
                    </div>

                    <div className="news_events_resources__news">
                        <div>
                            <h3>News</h3>
                            <div className="global__divider"></div>
                        </div>
                        <NewsSection />
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsPage;