import React from 'react'
import images from '../../../utils/imageLoader';

const GenotypeDrive = () => {
    return (
        <>
            <section className="new_page">
                <div className="genotype_drive__main flex flex-col-reverse desktop:flex-row justify-between gap-16 container">
                    <div className="flex flex-col gap-6 desktop:w-3/4">
                        <div>
                            <h2 className='pb-4'>Genotype Drive</h2>
                            <p className="t4">"Know Your Type: Live Right"</p>
                        </div>
                        <p className='text-[16px] laptop:text-[20px]'>Join us for the Optimum Health Global (OPHEG) Genotype Drive, <br /> health education of genotype and testing <br /> 30 randomly selected persons for free testing <br /> 100+ persons randomly selected for discounts</p>
                        <p className='text-[16px] laptop:text-[20px]'>Location: Optimum Diagnostics Center (OPP. Catholic Church Upmarket)</p>
                        {/* <p className='text-[16px] laptop:text-[20px]'>Invited Persons to Talk: </p>
                        <ul className='list-none'>
                            <li className='text-[14px] laptop:text-[16px]'></li>
                            <li className='text-[14px] laptop:text-[16px]'></li>
                        </ul> */}
                        <p className='text-[16px] laptop:text-[20px]'>Date: Oct 22nd - 23rd, 2024</p>
                        <p className='text-[16px] laptop:text-[20px]'>Time: 4:30PM</p>
                    </div>

                    <div>
                        <img className='w-full h-full object-contain tablet:w-[60vw] desktop:h-3/4 mx-auto' src={images['Genotype_Drive__Event.jpg']} alt="genotype drive event" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default GenotypeDrive; 