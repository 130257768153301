import React from 'react'
import EventDeftform from '../../components/eventDeftform';
import images from '../../utils/imageLoader';

const MissionVision = () => {
    return (
        <>
            <section className='new_page'>
                <div className='missionvission__main flex flex-col gap-16 container'>
                    <div className='grid grid-rows-subgrid grid-cols-1 laptop:grid-rows-2 laptop:grid-cols-2 gap-x-4 gap-y-16'>
                        <div className='flex flex-col justify-start gap-4'>
                            <h2>Mission</h2>
                            <p className='t4'>The mission of the Eben Family Sickle Cell Foundation is to empower and uplift individuals affected by Sickle Cell Anemia. We are dedicated to:</p>
                            <ul className='list-disc pl-6'>
                                <li>
                                    <p className='t5'>Providing Comprehensive Support: Offering resources, education, and direct assistance to patients and their families.</p>
                                </li>
                                <li>
                                    <p className='t5'>Raising Awareness: Educating the public about Sickle Cell Anemia to foster understanding and advocacy.</p>
                                </li>
                                <li>
                                    <p className='t5'>Promoting Health and Wellness: Facilitating access to healthcare, encouraging preventive measures, and supporting long-term care strategies.</p>
                                </li>
                                <li>
                                    <p className='t5'>Building a Compassionate Community: Creating a network of support where individuals can connect, share experiences, and find</p>
                                </li>
                            </ul>
                            <p className='t4'>Through our initiatives, we aim to alleviate the challenges associated with Sickle Cell Anemia and improve the overall well-being of those affected.</p>
                        </div>

                        <div className='my-auto'>
                            <img className='grayscale' src={images['MissionVision__Mission.png']} alt="" />
                        </div>

                        <div className='flex flex-col justify-start gap-4 laptop:order-4'>
                            <h2>Vision</h2>
                            <p className='t4'>Our vision is to create a world where individuals with Sickle Cell Anemia can lead fulfilling, healthy lives. We envision:</p>
                            <ul className='list-disc pl-6'>
                                <li>
                                    <p className='t5'>Enhanced Quality of Life: Ensuring that all individuals with Sickle Cell Anemia have access to the care, resources, and support they need to thrive.</p>
                                </li>
                                <li>
                                    <p className='t5'>Global Awareness and Understanding: Increasing global awareness about Sickle Cell Anemia and reducing the stigma associated with the condition.</p>
                                </li>
                                <li>
                                    <p className='t5'>Empowered Communities: Building strong, informed communities that support and advocate for individuals with Sickle Cell Anemia.</p>
                                </li>
                                <li>
                                    <p className='t5'>Innovative Solutions and Care: Promoting research, advancing treatment options, and advocating for policies that improve care and outcomes for patients.</p>
                                </li>
                            </ul>
                        </div>

                        <div className='my-auto laptop:order-3'>
                            <img className='grayscale' src={images['MissionVision__Vision.png']} alt="" />
                        </div>
                    </div>
                    <p className='t5'>
                        Our commitment is to drive positive change, support meaningful advancements in care, and foster a compassionate community where no one faces the journey with Sickle Cell Anemia alone. <br /> <br />
                        <strong className='text-[16px] laptop:text-[20px]'>Discover Our Mission: </strong><br />
                        To learn more about our journey, our guiding principles, and the impact we strive to make, please visit our Mission and Vision page. <br />
                        This comprehensive content provides a clear and engaging overview of the foundation's purpose, goals, and aspirations, inviting users to explore further into the heart of the Eben Family Sickle Cell Foundation.
                    </p>
                </div>
            </section>

            
            <section>
                <div className="flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0">
                    <div>
                        <h2 className='pb-4'>GET IN TOUCH</h2>
                        <p className='t4'>Let us know how we can help</p>
                    </div>
                    
                    <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />
                </div>
            </section>
        </>
    )
}

export default MissionVision;