import React from 'react'
import EventSection from '../../components/newsEventsResourcesCards/eventSection';

const EventsPage = () => {
    return (
        <>
            <section className="new_page">
                <div className="events__main flex flex-col gap-16 container">
                    <div className="events__upper">
                        <h2>Upcoming and Past Events</h2>
                        {/* <div className="global__divider" /> */}
                        <p className="t4 pt-2">Join Us in Our Mission to Support the Sickle Cell Community</p>
                        <p className='pt-2'>Welcome to our Events page, where you can stay informed about the latest opportunities to get involved with the Eben Family Sickle Cell Foundation. Whether you’re interested in attending an upcoming event or reflecting on the success of past gatherings, this is the place to be. Our events play a crucial role in raising awareness, building community support, and advancing our mission to improve the lives of those affected by sickle cell disease. We invite you to join us in making a difference.</p>
                    </div>

                    <div className="news_events_resources__events">
                        <div>
                            <h3>Events</h3>
                            <div className="global__divider"></div>
                        </div>
                        <EventSection />
                    </div>
                </div>
            </section>
        </>
    )
}

export default EventsPage;