import { Box, IconButton, Modal, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { muiTheme } from '..';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { mobile: '85vw', desktop: '85%' },
    maxWidth: 'calc((95vh / 0.5625) - 64px)',
    maxHeight: '95vh',
    bgcolor: 'var(--clr-black)',
    borderRadius: '16px',
    border: '2px solid hsl(45, 80%, 40%)',
    boxShadow: 24,
    padding: '32px 16px',
};

const IntroModal = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // localStorage.removeItem('firstVisit');
        const firstVisit = localStorage.getItem('firstVisit')
        if (!firstVisit) {
            setOpen(true);
            localStorage.setItem('firstVisit', 'true');
        }
    }, [])
    
    const handleClose = () => { setOpen(false) };

    return (
        <ThemeProvider theme={muiTheme}>
            <div>
                <Modal 
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="intro-modal"
                    aria-describedby="intro-video"
                >
                    <Box sx={style}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                            position: 'absolute',
                            right: '-2px',
                            top: '-2px',
                            color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className="relative pb-[56.25%] h-0">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full"
                                src="https://www.youtube.com/embed/uOdoK2fVh4Q?autoplay=1&loop=1&rel=0&controls=0" 
                                title="Intro Video" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                                referrerPolicy="strict-origin-when-cross-origin" 
                            ></iframe>
                        </div>
                    </Box>
                </Modal>
            </div>
        </ThemeProvider>
    )
}

export default IntroModal;