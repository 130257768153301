import React from 'react'
import Gallery from '../../components/gallery';

const HpGallery = () => {
    return (
        <div className="hp_gallery__main flex flex-col gap-16 container">
            <div>
                <h2 className='pb-2'>A Dialogue on Sickle Cell</h2>
                <p className="t4">Past Event: September 28, 2024</p>
            </div>
            <div className='hp_gallery__gallery'>
                <Gallery />
            </div>
        </div>
    )
}

export default HpGallery;