import React from 'react'
import images from '../../utils/imageLoader';
import { Carousel } from 'react-responsive-carousel';

const Programs = () => {
    return (
        <>
            <section className='new_page'>
                <div className="program_overview__main flex flex-col laptop:flex-row justify-between gap-8 container">
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2'>
                            <h2>Our Programs</h2>
                            <p className="t4">Empowering Lives through Comprehensive Support</p>
                            <p className="t5">A summary of the foundation’s programs, emphasizing their impact on individuals and communities affected by sickle cell disease.</p>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <h3>Programs Categories:</h3>
                            <div className='flex flex-col gap-2'>
                                <p className="t4">Support Services</p>
                                <p className="t5">Providing essential resources, counseling, and emotional support to sickle cell patients and their families.</p>
                                <p className="t4">Key Programs:</p>
                                <ul className="list-disc pl-6 flex flex-col gap-2">
                                    <li><p>Patient Support Groups: </p></li>
                                    <p>Regular meetings and online communities for patients and families.</p>
                                    <li><p>Emergency Assistance: </p></li>
                                    <p>Financial support and resources for those in immediate need.</p>
                                    <li><p>Family Counseling: </p></li>
                                    <p>Therapy sessions and counseling for families affected by sickle cell disease.</p>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Carousel className='w-[80vw] max-w-[608px] mx-auto desktop:mx-0' 
                            autoPlay 
                            interval={10000} 
                            infiniteLoop={true} 
                            swipeable={false} 
                            dynamicHeight={true}
                            renderIndicator={false} 
                            showThumbs={true}
                            thumbWidth={100}
                        >
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program1.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program2.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program3.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program4.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program1.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program2.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program3.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program4.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program1.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program2.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program3.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program4.png']} alt="gallery_content" />
                            </div>
                            <div>
                                <img className='slideshow__img' src={images['ProgramOverview__Program1.png']} alt="gallery_content" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>

            <section>
                <div className="other_programs__main flex flex-col gap-8 container">
                    <h2>Other Programs</h2>
                    {/* Other Programs Section */}
                </div>
            </section>
        </>
    )
}

export default Programs;