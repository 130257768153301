import React from 'react'
import { useMobileNav } from '../../utils/MobileNavContext';
import '../../style/style-components/style-buttons/hamburgerMenu.css';

const HamburgerMenu = () => {
    const { handleMenuBurger } = useMobileNav();

    return (
        <div className="header__burger" onClick={handleMenuBurger}>
            <div className="header__burger-bars" />
        </div>
    )
}

export default HamburgerMenu;