import { Button, ThemeProvider } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ChevronRight';
import { muiTheme } from '../..';

const ButtonPrimary = ({ btnText, link, linkNewTab, sx }) => {
    const defaultSx = { 
        backgroundColor: 'var(--clr-dark-background)', color: 'var(--clr-white)', fontSize: { mobile: '16px', tablet: '20px', laptop: '24px' }, textTransform: 'none', 
        height: '46px', borderRadius: '8px', 
        ":hover": { backgroundColor: 'var(--clr-black)' }
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Button 
                variant='contained' 
                endIcon={<ArrowForwardIcon />}
                LinkComponent={Link} to={link != null ? link : ''} target={linkNewTab != null ? "_blank" : ''} rel="noopener noreferrer"
                sx={sx != null ? [defaultSx, sx] : [defaultSx]}
            >
                {btnText}
            </Button>
        </ThemeProvider>
    )
}

export default ButtonPrimary;