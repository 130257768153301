import React, { useState } from 'react'
import '../style/style-components/header.css';
import images from '../utils/imageLoader';
import HamburgerMenu from './buttons/hamburgerMenu';
import { Button, Menu, MenuItem, ThemeProvider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { muiTheme } from '..';
import ButtonPrimary from './buttons/buttonPrimary';
// import { useMobileNav } from '../utils/MobileNavContext';

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currMenu, setCurrMenu] = useState('');
    const open = Boolean(anchorEl);
    let hoveringMenu = false;

    const handleHover = (event) => {
        if (anchorEl !== event.currentTarget || hoveringMenu) {
            setAnchorEl(event.currentTarget);
            setCurrMenu(event.currentTarget.innerText);
        }
    }

    const handleMenuHover = (event) => {
        if (!hoveringMenu) {
            hoveringMenu = true;
        }
    }
    
    const stopHover = () => {
        hoveringMenu = false;
        setTimeout(() => {
            if (!hoveringMenu) {
                handleMenuClose();
            }
        }, 50);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    // const { handleMenuBurger } = useMobileNav();    

    return (
        <ThemeProvider theme={muiTheme}>
            <div className='header__main container'>
                <div className='header__left'>
                    <HamburgerMenu />
                    <Link to='/'>
                        <img className="header__left_logo" src={images['Header__Logo.png']} alt="" />
                    </Link>
                </div>

                <ul className='header__nav text-[var(--clr-black)]'>
                    <li className='header__nav_item'>
                        <Button
                            LinkComponent={Link} to='/about-us'
                            variant='contained'
                            aria-controls={open ? 'navigation-menu-1' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onMouseOver={handleHover}
                            onMouseLeave={stopHover}
                            endIcon={<ArrowForwardIcon />}
                            sx={{ backgroundColor: 'var(--clr-white)', color: 'var(--clr-black)', textTransform: 'initial', padding: '6px 12px',
                                ":hover": { backgroundColor: 'var(--clr-black)', color: 'var(--clr-white)' }, 
                                boxShadow: 'none', fontSize: { mobile: '14px', desktop: '16px' } }}
                        >
                            About Us
                        </Button>
                        <Menu
                            id="header-menu"
                            anchorEl={anchorEl}
                            open={(currMenu === 'About Us') ? open : false}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                onMouseEnter: handleMenuHover,
                                onMouseLeave: stopHover,
                                style: { pointerEvents: 'auto' }
                            }}
                            sx={{ 
                                pointerEvents: 'none', 
                                '& .MuiMenu-paper': { zIndex: '999', marginTop: '2px' } 
                            }}
                        >
                            <Link to='/about-us/history'><MenuItem onClick={handleMenuClose}>History</MenuItem></Link>
                            <Link to='/about-us/mission-vision'><MenuItem onClick={handleMenuClose}>Mission & Vision</MenuItem></Link>
                            <Link to='/about-us/ways-to-give'><MenuItem onClick={handleMenuClose}>Ways to Give</MenuItem></Link>
                            <Link to='/about-us/get-involved'><MenuItem onClick={handleMenuClose}>Get Involved</MenuItem></Link>
                        </Menu>
                    </li>

                    <li className='header__nav_item'>
                        <Button
                            LinkComponent={Link} to='/our-work'
                            variant='contained'
                            aria-controls={open ? 'navigation-menu-2' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onMouseOver={handleHover}
                            onMouseLeave={stopHover}
                            endIcon={<ArrowForwardIcon />}
                            sx={{ backgroundColor: 'var(--clr-white)', color: 'var(--clr-black)', textTransform: 'initial', padding: '6px 12px',
                                ":hover": { backgroundColor: 'var(--clr-black)', color: 'var(--clr-white)' }, 
                                boxShadow: 'none', fontSize: { mobile: '14px', desktop: '16px' } }}
                        >
                            Our Work
                        </Button>
                        <Menu
                            id="header-menu"
                            anchorEl={anchorEl}
                            open={(currMenu === 'Our Work') ? open : false}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                onMouseEnter: handleMenuHover,
                                onMouseLeave: stopHover,
                                style: { pointerEvents: 'auto' }
                            }}
                            sx={{ 
                                pointerEvents: 'none', 
                                '& .MuiMenu-paper': { zIndex: '999', marginTop: '2px' }  
                            }}
                        >
                            <Link to='/our-work/program-overview'><MenuItem onClick={handleMenuClose}>Program Overview</MenuItem></Link>
                            <Link to='/our-work/impact-and-achievements'><MenuItem onClick={handleMenuClose}>Impact & Achievements</MenuItem></Link>
                        </Menu>
                    </li>

                    {/* <li className='header__nav_item'>
                        <Button
                            LinkComponent={Link} to='/news-resources'
                            variant='contained'
                            aria-controls={open ? 'navigation-menu-4' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onMouseOver={handleHover}
                            onMouseLeave={stopHover}
                            endIcon={<ArrowForwardIcon />}
                            sx={{ backgroundColor: 'var(--clr-white)', color: 'var(--clr-black)', textTransform: 'initial', padding: '6px 12px',
                                ":hover": { backgroundColor: 'var(--clr-black)', color: 'var(--clr-white)' }, 
                                boxShadow: 'none', fontSize: { mobile: '14px', desktop: '16px' } }}
                        >
                            News & Resources
                        </Button>
                        <Menu
                            id="header-menu"
                            anchorEl={anchorEl}
                            open={(currMenu === 'News & Resources') ? open : false}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                onMouseEnter: handleMenuHover,
                                onMouseLeave: stopHover,
                                style: { pointerEvents: 'auto' }
                            }}
                            sx={{ 
                                pointerEvents: 'none', 
                                '& .MuiMenu-paper': { zIndex: '999', marginTop: '2px' }  
                            }}
                        >
                            <Link to='/news-resources/news'><MenuItem onClick={handleMenuClose}>Eben Family News</MenuItem></Link>
                            <Link to='/news-resources/events'><MenuItem onClick={handleMenuClose}>Upcoming and Past Events</MenuItem></Link>
                            <Link to='/news-resources/guides'><MenuItem onClick={handleMenuClose}>Guides & Articles</MenuItem></Link>
                            <Link to='/news-resources/videos'><MenuItem onClick={handleMenuClose}>Educational Videos</MenuItem></Link>
                            <Link to='/news-resources/support'><MenuItem onClick={handleMenuClose}>Patient Support</MenuItem></Link>
                        </Menu>
                    </li> */}
                    <li className='header__nav_item'>
                        <Button
                            LinkComponent={Link} to='/news-resources'
                            variant='contained'
                            sx={{ backgroundColor: 'var(--clr-white)', color: 'var(--clr-black)', textTransform: 'initial', padding: '6px 12px',
                                ":hover": { backgroundColor: 'var(--clr-black)', color: 'var(--clr-white)' }, 
                                boxShadow: 'none', fontSize: { mobile: '14px', desktop: '16px' } }}
                        >
                            News & Resources
                        </Button>
                    </li>

                    <li className='header__nav_item'>
                        <Button
                            LinkComponent={Link} to='/contact'
                            variant='contained'
                            sx={{ backgroundColor: 'var(--clr-white)', color: 'var(--clr-black)', textTransform: 'initial', padding: '6px 12px',
                                ":hover": { backgroundColor: 'var(--clr-black)', color: 'var(--clr-white)' }, 
                                boxShadow: 'none', fontSize: { mobile: '14px', desktop: '16px' } }}
                        >
                            Contact
                        </Button>
                    </li>
                </ul>

                <Link to="/news-resources/icone-brunch">
                    <img className='header__right_logo w-[110px]' src={images['Header__Logo2.png']} alt="header_logo" />
                </Link>

                <div className='header__right'>
                    <ButtonPrimary link="/about-us/donate" btnText="Donate" sx={{ 
                        width: { mobile: '100px', tablet: '136px' }, fontSize: { mobile: '16px', tablet: '20px' } 
                    }} />
                </div>
            </div>
        </ThemeProvider>
    )
}

export default Header;