import React from 'react';
import '../../style/style-pages/homepage/NewsEvents.css';
import images from '../../utils/imageLoader';
import ButtonSecondary from '../../components/buttons/buttonSecondary';
import NewsCard from '../../components/newsEventsResourcesCards/newsCard';
import EventCard from '../../components/newsEventsResourcesCards/eventCard';
import ResourcesCard from '../../components/newsEventsResourcesCards/resourcesCard';
import { eventCards } from '../../components/newsEventsResourcesCards/eventSection';

const item = eventCards[0];

const NewsEvents = () => {
  return (
    <div className='news_events__main container'>
        <div className="news_events__upper flex flex-col gap-4">
            <h2 className='pb-4'>News, Events and Resources</h2>
            <p className='text-[20px] laptop:text-[24px]'>Stay informed with the latest updates and stories from the Eben Family Sickle Cell Foundation.</p>
        </div>


        <div className="news_events__content flex flex-col justify-between gap-6 laptop:grid laptop:grid-cols-3 laptop:grid-rows-auto pt-16">
            <div className="news_events__content_section laptop:col-span-2">
                <h4>Events</h4>
                <div className='global__divider' />
                <div className="news_events__cards gap-6">
                    <EventCard 
                        title={item.title}
                        subtitle={item.subtitle}
                        date={item.date}
                        reservation={item.reservation}
                        description={item.description}
                        link={item.link}
                        image={item.image}
                    />
                    {/* <EventCard 
                        title="Genotype Drive"
                        subtitle="Know Your Type: Live Right"
                        date="October 22 - 23, 2024"
                        reservation="Click below to view info"
                        description="Join us for the Optimum Health Global (OPHEG) Genotype Drive, health education of genotype and testing 30 randomly selected persons for free testing 100+ persons randomly selected for discounts"
                        link='/news-resources/genotype-drive'
                        image={images['Genotype_Drive__Event.jpg']}
                    /> */}
                    {/* <EventCard
                        title='A Dialogue on Sickle Cell' 
                        subtitle='Learn. Connect. Advocate' 
                        date='September 28, 2024' 
                        reservation='Click below to RSVP'
                        description='Join us in Dallas on September 28th during Sickle Cell Awareness Month for an inspiring event with sickle cell warriors! Learn from experts, connect with fellow warriors, and advocate for better research funding, access to care, and policy advancements.'
                        link='/news-resources/dialogue-sickle-cell'
                        image={images['NewsEvents__Card1.png']}
                    /> */}
                    <EventCard
                        title='Icône Brunch'
                        subtitle='Stay tuned and sign up to get latest information about next icôneBrunch.'
                        date='Fall 2025'
                        description='Join us for the Icône Brunch 2025, a signature event that brings together community leaders, advocates, and supporters to celebrate and advance the mission of the Eben Family Sickle Cell Foundation.'
                        link='/news-resources/icone-brunch'
                        image={images['NewsEvents__Card2.png']}
                    />
                </div>
                <div className="news_events__cards_btn ml-auto mt-4">
                    <ButtonSecondary btnText="SEE ALL" link='/news-resources/events' />
                </div>
            </div>

            
            <div className="news_events__content_section laptop:col-span-1">
                <h4>Brochures And Guides</h4>
                <div className='global__divider' />
                <div className="news_events__cards gap-4">
                    <ResourcesCard 
                        title='Understanding Sickle Cell Disease' 
                        description='Dowloadable PDF explaining the basics of sickle cell disease, its symptoms and treatment options.' 
                        link='https://acrobat.adobe.com/id/urn:aaid:sc:US:08190163-6f6a-4616-bf19-0deb75055e9a'
                    />
                    <ResourcesCard 
                        title='Sickle Cell Disease Prevention and Treatment'
                        description='Practical tips and strategies for patients and families to manage the condition effectively at home.'
                        link='https://acrobat.adobe.com/id/urn:aaid:sc:US:b6793f8d-dd58-4c13-b4ef-373d9d1c32c3'
                    />
                    <ResourcesCard
                        title='Nutrition and Sickle Cell:'
                        description='Information on dietary choices that can help manage sickle cell symptoms.'
                        link='https://acrobat.adobe.com/id/urn:aaid:sc:US:d707df78-8422-4fdb-9942-4e2152628061'
                    />
                </div>
                <div className="news_events__cards_btn ml-auto mt-4">
                    <ButtonSecondary btnText="SEE ALL" link='/news-resources/guides' />
                </div>
            </div>

            
            <div className="news_events__content_section laptop:col-span-2 laptop:row-span-2">
                <h4>News</h4>
                <div className='global__divider' />
                <div className="news_events__cards gap-6">
                    <NewsCard title="2024 First Quater Event" image={images['NewsEvents__Card3.png']} />
                    <NewsCard title="Sickle Cell Month Nationwide Information" image={images['NewsEvents__Card4.png']} />
                </div>
                <div className="news_events__cards_btn ml-auto mt-4">
                    <ButtonSecondary btnText="SEE ALL" link='/news-resources/news' />
                </div>
            </div>


            <div className="news_events__content_section laptop:col-span-1">
                <h4>Videos and Webinars</h4>
                <div className='global__divider' />
                <div className="news_events__cards gap-4">
                    <ResourcesCard
                        title='A Dialogue On Sickle Cell'
                        description='In this open conversation with friends, Emmanuel dives into the topic of Sickle Cell Disease from a personal and relatable perspective.'
                        link='https://youtu.be/uOdoK2fVh4Q?si=9VlBYnH43_8lKEcX'
                    />
                </div>
                <div className="news_events__cards_btn ml-auto mt-4">
                    <ButtonSecondary btnText="SEE ALL" link='/news-resources/videos' />
                </div>
            </div>


            <div className="news_events__content_section laptop:col-span-1">
                <h4>Patient Support</h4>
                <div className='global__divider' />
                <div className="news_events__cards gap-4">
                    <ResourcesCard
                        title='Texas Health and Human Services'
                        description='Information on local and online support groups for patients and families.' 
                        link='https://www.dshs.texas.gov/newborn-screening-program/sickle-cell-disease/sickle-cell-resources'
                    />
                </div>
                <div className="news_events__cards_btn ml-auto mt-4">
                    <ButtonSecondary btnText="SEE ALL" link='/news-resources/support' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsEvents;