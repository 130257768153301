import React from 'react'
import EventDeftform from '../../components/eventDeftform';
// import ButtonPrimary from '../../components/buttons/buttonPrimary';

const HpContact = () => {
    return (
        <div className='hp_contact__main flex flex-col gap-10 max-w-[696px] mx-auto p-4 tablet:p-0'>
            <div className="hp_contact__upper">
                <h2 className='pb-4'>GET IN TOUCH</h2>
                <p className='t4'>Let us know how we can help</p>
            </div>

            <EventDeftform formId="0865f795-9a15-435a-83b0-430703ce367c" />

            {/* <div className="hp_contact__form flex flex-col">
                <label className='mb-2 text-[20px]' htmlFor="contactName">Name</label>
                <input className='mb-4 p-3 rounded-lg border border-[var(--clr-gray250)] text-[16px]' placeholder='Your full name' type="text" />
                <label className='mb-2 text-[20px]' htmlFor="contactEmail">Email</label>
                <input className='mb-4 p-3 rounded-lg border border-[var(--clr-gray250)] text-[16px]' placeholder='me@company.com' type="text" />
                <label className='mb-2 text-[20px]' htmlFor="Message">Message</label>
                <textarea className='p-3 rounded-lg border border-[var(--clr-gray250)] min-h-[100px] text-[16px]' name="contactMessage" id="hp_contact__form_message" placeholder='Your message...'></textarea>
            </div>
            <div className="hp_contact__form_btn ml-auto">
                <ButtonPrimary btnText="Send Message" />
            </div> */}
        </div>
    )
}

export default HpContact;