import React from "react";
import '../style/style-components/footer.css';
import images from "../utils/imageLoader";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className='footer__main container flex flex-col gap-16 laptop:gap-32 text-[var(--clr-white)]'>
            <div className='footer__upper flex flex-col laptop:flex-row justify-between gap-10'>
                <div className="flex flex-col gap-10">
                    <Link to="/">
                        <img className="w-[262px] h-[74px] mx-auto" src={images['Footer__Logo.png']} alt='logo' />
                    </Link>
                    {/* <div className="flex py-2 px-4 gap-2 items-center">
                        <img className="w-5 h-5" src={images['Footer__IconPhone.svg']} alt="phone-icon" />
                        <h6>(777) 777-777</h6>
                    </div> */}
                </div>
                <div className='footer__links grid grid-cols-2 tablet:flex tablet:flex-row gap-8 tablet:gap-32 mx-auto tablet:mx-0 text-left'>
                    <div className='footer__links_company flex flex-col gap-6'>
                        <p className=" font-bold">COMPANY</p>
                        <ul className="footer__links_ul">
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/our-work">Our Work</Link></li>
                            <li><Link to="/news-resources">News & Resources</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className='footer__links_about flex flex-col gap-6'>
                        <p className=" font-bold">ABOUT</p>
                        <ul className="footer__links_ul">
                            <li><Link to='/terms-conditions'>Terms & Conditions</Link></li>
                            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className='footer__links_social col-span-2 flex flex-col gap-6'>
                        <p className=" font-bold">SOCIAL LINKS</p>
                        <ul className="footer__links_ul grid grid-rows-2 grid-cols-3 gap-10">
                            <img className="footer__social_icon" src={images['Footer__Icon1.svg']} alt="social-icon" />
                            <Link to="https://www.instagram.com/ebenfamilyscf/" target="_blank">
                                <img className="footer__social_icon" src={images['Footer__Icon2.svg']} alt="social-icon" />
                            </Link>
                            <img className="footer__social_icon" src={images['Footer__Icon3.svg']} alt="social-icon" />
                            <Link to="https://www.youtube.com/@EbenFamilySickleCellFoundation" target="_blank">
                                <img className="footer__social_icon" src={images['Footer__Icon4.svg']} alt="social-icon" />
                            </Link>
                            <img className="footer__social_icon" src={images['Footer__Icon5.svg']} alt="social-icon" />
                            <img className="footer__social_icon" src={images['Footer__Icon6.svg']} alt="social-icon" />
                        </ul>
                    </div>
                </div>
            </div>
            <p>Copyright © 2024 All Right Reserved to Eben Family Sickle Cell Foundation. | Created by <Link className="underline" to="https://www.wisecursor.com" target="_blank" rel="noopener noreferrer">WiseCursor</Link>.</p>
        </div>
    )
}

export default Footer;